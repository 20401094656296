<!-- START firstScreen -->
<div [ngClass]="{'wordpress': true}">
  <section class="firstScreen">
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>EL MEJOR <br> HOSTING PARA <br> WORDPRESS</h1>
        <div class="firstScreen__sub_ttl" i18n>Haz que tus ideas alcancen un nivel <br> superior
          con nuestro hosting. Ideal <br> para obtener lo mejor de Wordpress</div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad bg_blue_grad pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_white" i18n>Planes</div>
    <h2 class="ttl2_white mb" i18n>Menor precio, mayor rapidez.</h2>

    <div class="wrapCardPrice pad_buttom">
      <div class="cardPrice" *ngFor="let plan of category.plans">
        <div class="cardPrice__top">
          <div class="cardPrice_ttl"></div>
          <div class="cardPrice__ttl">{{ plan.name }}</div>
          <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
          <div *ngFor="let period of plan.periods; let i = index">
            <p class="price price_ttal">
              <span *ngIf="i > 0" class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
            </p>
            <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
            <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>

        <button id="wordpress-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )"
          i18n>contratar</button>

        <ul class="card_list">
          <li *ngFor="let feature of plan.features">
            <span [innerHTML]="feature.text"></span>
            <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
          </li>
        </ul>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START menuPlans-->
<app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists" [preRender]="false" (deferLoad)="showImage1 = true; addVideoControlsForMobyle()"></app-block-list>
<!-- END blockList -->

<!-- START videoCompany -->
<app-video-company [preRender]="false" (deferLoad)="showImage2 = true" [showImage]="showImage1"></app-video-company>
<!-- END videoCompany -->

<!-- START -->
<section class="pad">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El hosting para tu Wordpress</div>
    <h2 class="ttl2_black mb" i18n>¿Por qué un plan Wordpress?</h2>

    <div class="content">
      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img1_service.svg" alt="imagen de versatilidad">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Dale la forma que quieras a tus ideas</h3>
          <h4 class="ttl4" i18n>Versatilidad</h4>
          <p i18n>Wordpress es la plataforma Open Source más versátil del mercado, gracias a la facilidad de integración
            de Apps y Scripts. Lo que significa una oportunidad insuperable para los que buscan sacar el mejor provecho
            a su inversión.</p>
        </div>
      </div>

      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img18_service.svg" alt="imagen de proyectos">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Comienza tus proyectos ya</h3>
          <h4 class="ttl4" i18n>Instalación gratuita</h4>
          <p i18n>Lo mejor de Wordpress es la facilidad y la rapidez con la que puedes obtener resultados concretos.
            Como no se necesitan profundos conocimientos de programación, en solo unos minutos ya tendrás tu sitio
            preparado para lo que quieras.</p>
        </div>
      </div>

      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img19_service.svg" alt="imagen de personalización">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Personalización absoluta</h3>
          <h4 class="ttl4" i18n>Cientos de plantillas y diseños</h4>
          <p i18n>Una de las principales características de esta popular plataforma, es que está pensada para alcanzar
            asombrosos niveles de personalización no solo en términos de diseño, si no que también fue construida bajo
            estándares de códigos amigables para aceptar lo que necesites integrar.</p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad_top bg_grey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El Hosting para tu Wordpress</div>
    <h2 class="ttl2_black mb" i18n>Llevamos tus ideas al siguiente nivel</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img13_column.svg" alt="Más provecho">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Consigue el mejor provecho de Wordpress</h3>
        <p i18n>Instalación inmediata y asistencia completa durante el proceso inicial de un plan pensado
          específicamente para los que buscan optimizar su negocio On-line a través de Wordpress.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img14_column.svg" alt="Más protección">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Sistema de protección de tu cuenta</h3>
        <p i18n>¡Ahora tu cuenta Wordpress es mucho más segura! Con nuestra exclusiva configuración pensada en el
          bloqueo oportuno de IP intrusas, obtendrás más seguridad para ti y tus visitas.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img15_column.svg" alt="Más integración">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height" i18n>Integración absoluta de pago</h3>
        <p i18n>El plan NEGOCIOS Wordpress, está optimizado, incluso desde los themes, para ser totalmente compatible
          con la integración de plataformas de pago como WebPay de Transbank trabajando directamente o con pluggins como
          WooCommerce.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad bg_white" [preRender]="false" (deferLoad)="showImage3 = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características del plan Wordpress</div>
    <h2 class="ttl2_black mb" i18n>Más ventajas para obtener lo mejor de Wordpress</h2>

    <div class="wrapCollumn">
      <div class="wrapCollumn__column">
        <h3 class="ttl3 upper" i18n>Mejora tu experiencia en Wordpress</h3>
        <p i18n>Disminuimos la densidad de clientes por servidor y asignamos más recursos para cumplir con todos los
          requerimientos que Wordpress necesita. Aumenta la rapidez de tus procesos y mejora la experiencia de tus
          usuarios.</p>
        <h3 class="ttl3 upper" i18n>Recursos Optimizados para tu Wordpress</h3>
        <p i18n>Debido a la gran cantidad de recursos que Wordpress exige de los servidores donde se aloja,
          personalizamos un plan más potente y con más nivel de RAM y CPU para evitar las ralentizaciones.</p>
        <h3 class="ttl3 upper" i18n>Análisis exhaustivos constantemente</h3>
        <p i18n>Wordpress cuenta con más de 75 millones de usuarios, es por esto que miles de malwares y códigos
          maliciosos son creados a cada minuto. Por suerte creamos potentes configuraciones de protección WAF y DDOS,
          que son acompañadas por análisis constantes efectuados por nuestros servidores.</p>
      </div>
      <div class="wrapCollumn__img">
        <ng-container *ngIf="showImage2">
          <img src="/assets/images/img16_column.svg" alt="El Hosting para tu Negocio">
        </ng-container>
      </div>
    </div>

  </div>
</section>
<!-- END -->


<!-- START blockClients -->
<ng-container *ngIf="showSectionSlider">
  <app-block-clients></app-block-clients>
</ng-container>
<!-- END blockClients -->

<!-- START blockInfo -->
<app-block-info [info]="info"></app-block-info>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege sino que acelera miles de sitios web y otras aplicaciones de
      internet.</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y
            SSL entre otras herramientas para defender tu sitio.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots que tecnologías de análisis
            convencionales no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Qué es Wordpress?</h3>
        <p i18n>Wordpress es un gestor de contenido, el más utilizado a nivel mundial. Su flexibilidad permite crear una
          variada gama de plataformas, desde un blog, hasta una página web avanzada.</p>
        <h3 class="ttl3" i18n>¿Necesito conocimientos en programación para usar Wordpress?</h3>
        <p i18n>NO, Wordpress puede ser utilizado por todo aquel espíritu emprendedor con ganas de publicar su marca en
          internet. Si ya tienes conocimientos para poder revisar tu correo electrónico, escribir un post en Facebook o
          navegar en red, ¡Ya estas listo para crear tu propio sitio Wordpress!</p>
        <h3 class="ttl3" i18n>¿Puedo migrar mi sitio Wordpress a BlueHosting?</h3>
        <p i18n>Por supuesto, migramos tu sitio Wordpress completamente GRATIS. Experimenta más velocidad y rendimiento
          en tu sitio Wordpress, con nuestros planes diseñados a la medida.</p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Wordpress es lento?</h3>
        <p i18n>Para nada, nuestros planes Web Hosting NEGOCIOS Wordpress están diseñados para obtener el máximo nivel
          de rendimiento en esta poderosa herramienta. Nuestro equipo técnico diseña y destina los recursos óptimos para
          que tu sitio Wordpress sea el más rápido del universo.</p>
        <h3 class="ttl3" i18n>¿Puedo crear una tienda virtual?</h3>
        <p i18n>Gracias a la enorme flexibilidad que ofrece Wordpress, puedes crear tu propia tienda online en un par de
          pasos, todo esto gracias a la oferta existente de plugins para Wordpress. En este sentido, puedes descargar
          Woocommerce, un plugin con el cual podrás integrar de forma fácil una pasarela de pagos para tu propia tienda
          online.</p>
        <h3 class="ttl3" i18n>¿Cómo configuro mis DNS?</h3>
        <p i18n>Configurar los registros DNS es necesario para poder enlazar tu dominio con nuestro servidor; de esta
          manera, al escribir el nombre de dominio en un navegador, este se traducirá en una dirección, la cual
          pertenece a nuestro servidor. Los registros DNS para los planes WebHosting Wordpress son los siguientes:
        </p>
        <ol>
          <li>ns1.pymedns.net</li>
          <li>ns2.pymedns.net</li>
          <li>ns3.pymedns.net</li>
        </ol>
      </div>

    </div>
  </div>
</section>
<!-- END block_txt_column -->

<!-- START block_logo -->
<app-block-logos></app-block-logos>
<!-- END block_logo -->

<ng-container *ngIf="showFooter">
  <app-footer></app-footer>
</ng-container>