import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'app-dialog-syte-html',
  templateUrl: './dialog-syte-html.component.html'
})
export class DialogSyteHtmlComponent implements OnInit {

  url;

  constructor(private localeService: LocaleService) { 
    this.url = this.localeService.getContent().links.personas.dialogs.html;
  }

  ngOnInit() {
  }

  goToUrl(): void {
    window.open(this.url, '_blank');
  }
}
