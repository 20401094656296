export const content_co = {
    plans_Halloween: [
        {
            name: 'power',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=1&promocode=halloween&tld=.cl',
            sub: 'Recomendado para requerimientos puntuales',
            price_pre: '34900',
            price_actual: '27920',
            period: [
                {
                    period: '2 años',
                    price_pre: '22900',
                    price_actual: '18320',
                }, {
                    period: '3 años',
                    price_pre: '20900',
                    price_actual: '16720',
                }
            ],
            plan_list: [
                {
                    icon: 'icon-lightning',
                    text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                    alt: 'Hasta 50% más poderoso que los hosting comunes'
                }, {
                    icon: 'icon-web',
                    text: '1 sitio <b>web</b>',
                    alt: '1 sitio web'
                }, {
                    icon: 'icon-storage',
                    text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                    alt: 'Espacio almacenamiento SSD Ilimitado'
                }
            ]
        }, {
            name: 'full',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=1&promocode=halloween&tld=.cl',
            sub: 'Ideal para proyectos en crecimiento',
            price_pre: '38900',
            price_actual: '31120',
            period: [
                {
                    period: '2 años',
                    price_pre: '26900',
                    price_actual: '21520',
                }, {
                    period: '3 años',
                    price_pre: '24900',
                    price_actual: '19920',
                }
            ],
            plan_list: [
                {
                    icon: 'icon-lightning',
                    text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                    alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                }, {
                    icon: 'icon-web',
                    text: 'Sitios <b>web ilimitados</b>',
                    alt: 'Sitios web ilimitados'
                }, {
                    icon: 'icon-storage',
                    text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                    alt: 'Espacio almacenamiento SSD Ilimitado'
                }, {
                    icon: 'icon-domen',
                    text: '<b>Dominio .CL gratis</b> por 1 año',
                    alt: 'Dominio .CL gratis por 1 año'
                }
            ]
        }
    ],
    hosting: [
        {
            id: 1,
            category: 'Emprendedores',
            show: true,
            plans: [
                {
                    id: 1,
                    name: 'Power',
                    sub: 'Un plan preciso para requerimientos puntuales.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=6',
                    sub_detail: 'Comience con el plan perfecto para quienes están dispuesto a evolucionar en el mundo digital. Disponemos de los recursos precisos para que la inversión esté acorde con sus necesidades.',
                    recommended_to: ['Bloggers', 'Diseñadores'],
                    description: [
                        {
                            type: 'text',
                            info: '50% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1.5 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '200.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: 'Hasta <b>50% más poderoso</b> que hosting comunes'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a></b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>1 sitio</b> web'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '180000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '150000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '110000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'Full',
                    sub: 'Los recursos que sus proyectos exigen.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6',

                    sub_detail: 'Tenemos el plan perfecto para los que quieren hacer crecer su proyecto digital, con el espacio y la velocidad de trabajo precisa para alcanzar un desempeño deseable sin pagar de más.',
                    recommended_to: ['Bloggers', 'Diseñadores', 'Desarrolladores'],
                    description: [
                        {
                            type: 'text',
                            info: '50% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '2 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1.5 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '200.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: '<b>40% más poderoso</b> que plan Emprendedores Power'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a></b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: 'Sitios web <b>ilimitados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '210000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '170000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '130000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: 2,
            category: 'Negocios',
            show: true,
            plans: [
                {
                    id: 1,
                    name: 'Power',
                    sub: 'El plan de quienes se atreven a innovar.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=84&currency=6',
                    sub_detail: 'Descubra el soporte perfecto para los que se inician en el mundo digital. Colocamos a disposición de los Negocios los recursos precisos, con las tarifas más cómodas.',
                    recommended_to: ['Negocios', 'Proyectos personales', 'Desarrolladores', 'Diseñadores'],
                    description: [
                        {
                            type: 'text',
                            info: '100% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '3 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '2 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '400.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: '<b>2x más poderoso</b> que plan Emprendedores Full'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a></b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>1 sitio</b> web'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '320000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '280000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '220000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'Full',
                    sub: 'La elección de los que buscan evolucionar.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=87&currency=6',
                    sub_detail: 'La opción de quienes están dispuestos a impulsar su emprendimiento. Asegure ese paso, con un soporte web desarrollado para entregar seguridad y comodidad.',
                    recommended_to: ['Negocios', 'Proyectos personales', 'Desarrolladores', 'Diseñadores', 'E-commerce'],
                    description: [
                        {
                            type: 'text',
                            info: '140% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '4 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '2.5 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '400.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: '<b>40% más poderoso</b> que plan Negocios Power'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD <a class="has-icon" href="https://help.haulmer.com/hc/conocimientos-generales/conoce-nuestra-politica-de-recursos-ilimitados" target="_blank">Ilimitado</a></b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: 'Sitios web <b>ilimitados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '370000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '330000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '260000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: 3,
            category: 'Empresas',
            show: true,
            plans: [
                {
                    id: 1,
                    name: 'Power',
                    sub: 'El espacio y la velocidad que su empresa necesita.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=255&currency=6',
                    sub_detail: 'Toda empresa sabe lo importante de hacer las inversiones correctas. Este plan fue creado pensando en la conveniencia justa que se necesita para comenzar.',
                    recommended_to: ['Empresas', 'Revendedores', 'E-commerce', 'Agencias'],
                    description: [
                        {
                            type: 'text',
                            info: 'Hosting empresarial más poderoso del mercado',
                            isShowCell: true,
                            isFullWidth: true,
                            fullWidthNumber: 4
                        },
                        {
                            type: 'text',
                            info: '100 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '4 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '2 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: 'Hosting empresarial <b>más poderoso</b> del mercado'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: '<b>100 GB SSD</b> espacio de almacenamiento'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>4 GB dedicada</b> de memoria ram'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-key.svg',
                            text: '<b>2 vCore dedicada</b> CPU Intel® Xeon®'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-sound.svg',
                            text: 'Recursos <b>dedicados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: 'Sitios web <b>ilimitados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-lamp.svg',
                            text: 'Inodos <b>ilimitados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Base de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '930000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '750000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '660000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'Full',
                    sub: 'Mayor rendimiento para una exigencia superior.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=256&currency=6',
                    sub_detail: 'Este plan está enfocado en entregar el espacio y velocidad necesaria para empresas que buscan consolidar su presencia en el mundo web.',
                    recommended_to: ['Agencias', 'Estudios', 'Creadores de contenido', 'Empresas'],
                    description: [
                        {
                            type: 'text',
                            info: 'Hosting empresarial más poderoso del mercado',
                            isShowCell: false
                        },
                        {
                            type: 'text',
                            info: '150 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '6 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '3 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: 'Lo mismo del plan Power Empresa pero con:'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: '<b>150 GB SSD</b> espacio de almacenamiento'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>6 GB dedicada</b> de memoria ram'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-key.svg',
                            text: '<b>3 vCore dedicada</b> CPU Intel® Xeon®'
                        },
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '1900000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '1500000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '1300000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 3,
                    name: 'Ultra',
                    sub: 'Más poder y flexibilidad para varios sitios web.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=262&currency=6',
                    sub_detail: 'Consigue la cantidad de recursos que necesita para impulsar su empresa. Con este plan, logrará obtener el mejor rendimiento por la inversión correcta.',
                    recommended_to: ['Empresas', 'Creadores de contenido', 'E-commerce'],
                    description: [
                        {
                            type: 'text',
                            info: 'Hosting empresarial más poderoso del mercado',
                            isShowCell: false
                        },
                        {
                            type: 'text',
                            info: '200 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '8 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '4 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: 'Lo mismo del plan Full Empresa pero con:'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: '<b>200 GB SSD</b> espacio de almacenamiento'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>8 GB dedicada</b> de memoria ram'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-key.svg',
                            text: '<b>4 vCore dedicada</b> CPU Intel® Xeon®'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '2800000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '2250000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '2000000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 4,
                    name: 'Ultimate',
                    sub: 'Máxima disponibilidad de recursos.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=257&currency=6',
                    sub_detail: 'La velocidad necesaria para las empresas que buscan superar todos los límites y continuar desarrollándose en el mercado digital.',
                    recommended_to: ['Empresas', 'Desarrolladores', 'Creadores de contenido'],
                    description: [
                        {
                            type: 'text',
                            info: 'Hosting empresarial más poderoso del mercado',
                            isShowCell: false
                        },
                        {
                            type: 'text',
                            info: '250 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '10 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '5 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: 'Lo mismo del plan Ultra Empresa pero con:'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: '<b>250 GB SSD</b> espacio de almacenamiento'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>10 GB dedicada</b> de memoria ram'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-key.svg',
                            text: '<b>5 vCore dedicada</b> CPU Intel® Xeon®'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '3700000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '3000000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '2600000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: 4,
            category: 'Wordpress',
            show: false,
            plans: [
                {
                    id: 1,
                    name: 'Power',
                    sub: 'El plan de quienes se atreven a innovar.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=93&currency=6',
                    sub_detail: 'Descubra el soporte perfecto para los que se inician en el mercado digital. Ponemos a disposición de los Negocios, los recursos precisos por tarifas cómodas.',
                    recommended_to: ['Empresas', 'Proyectos personales', 'Desarrolladores', 'Diseñadores'],
                    description: [
                        {
                            type: 'text',
                            info: '100% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '3 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1.8 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '400.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '1 sitio',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: '<b>2x más poderoso</b> que plan hosting Emprendedores Full'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-tutorial-wordpress.svg',
                            text: 'Tutoriales <b>Wordpress</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-install-wordpress.svg',
                            text: '<b>Instalación gratis</b> Wordpress'
                        },
                        /*{
                            icon_url: '/assets/images/icons/icon-support-wordpress.svg',
                            text: '<b>Soporte especializado</b> Wordpress'
                        },*/
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD ilimitado</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: '<b>1 sitio</b> web'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '310000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '250000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '190000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                },
                {
                    id: 2,
                    name: 'Full',
                    sub: 'La elección de los que buscan evolucionar.',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=95&currency=6',
                    sub_detail: 'La opción de quienes están dispuestos a dar el siguiente paso en el crecimiento de su negocio. Asegure ese paso con un soporte web desarrollado para entregar seguridad y comodidad.',
                    recommended_to: ['Negocios', 'Proyectos personales', 'Desarrolladores', 'Diseñadores', 'E-commerce'],
                    description: [
                        {
                            type: 'text',
                            info: '140% + Poderoso',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '3.32 GB',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '2.6 vCore',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'closed',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '400.000',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'unlim',
                            isShowCell: true
                        },
                        {
                            type: 'text',
                            info: '10 sitios',
                            isShowCell: true
                        },
                        {
                            type: 'icon',
                            info: 'opened',
                            isShowCell: true
                        }
                    ],
                    features: [
                        {
                            icon_url: '/assets/images/icons/icon-lightning.svg',
                            text: '<b>40% más poderoso</b> que plan Wordpress Power'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-tutorial-wordpress.svg',
                            text: 'Tutoriales <b>Wordpress</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-install-wordpress.svg',
                            text: '<b>Instalación gratis</b> Wordpress'
                        },
                        /*{
                            icon_url: '/assets/images/icons/icon-support-wordpress.svg',
                            text: '<b>Soporte especializado</b> Wordpress'
                        },*/
                        {
                            icon_url: '/assets/images/icons/icon-storage.svg',
                            text: 'Espacio almacenamiento <b>SSD ilimitado</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-web.svg',
                            text: 'Sitios web <b>ilimitados</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-message.svg',
                            text: 'Cuentas de correo <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-base.svg',
                            text: 'Bases de datos <b>ilimitadas</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-chart.svg',
                            text: 'Transferencia de datos <b>sin medición</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-ssl.svg',
                            text: '<b>SSL Gratuito Let’s Encrypt</b>'
                        },
                        {
                            icon_url: '/assets/images/icons/icon-domen.svg',
                            text: '<b>Dominio .COM.CO gratis</b> por 1 año'
                        }
                    ],
                    periods: [
                        {
                            period: '1 año',
                            price: '370000',
                            description: null
                        },
                        {
                            period: '2 años',
                            price: '300000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis',
                                domain: 'true',
                                disccount: '20'
                            }
                        },
                        {
                            period: '3 años',
                            price: '220000',
                            description: {
                                text: 'Ahorro 20% | Dominio gratis | Soporte Vip',
                                domain: 'true',
                                disccount: '20'
                            }
                        }
                    ]
                }
            ]
        }
    ],
    clouds: [
        {
            id: 1,
            cloud: 'Estándar',
            description: 'Contamos con una amplia gama de planes pensados en cubrir el mayor porcentaje de sus necesidades. Sabemos que detrás de cada cliente, existe un universo de requerimientos e intentamos satisfacerlos todos.',
            types: [
                {
                    memory: '1 GB',
                    vcpus: '1 vCPU',
                    ssd: '20 GB',
                    traffic: '1 TB',
                    price: '11700',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=291&currency=6',
                    popular: false
                },
                {
                    memory: '2 GB',
                    vcpus: '1 vCPU',
                    ssd: '50 GB',
                    traffic: '2 TB',
                    price: '28000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=292&currency=6',
                    popular: false
                },
                {
                    memory: '4 GB',
                    vcpus: '2 vCPU',
                    ssd: '80 GB',
                    traffic: '4 TB',
                    price: '56000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=293&currency=6',
                    popular: true
                },
                {
                    memory: '8 GB',
                    vcpus: '4 vCPU',
                    ssd: '160 GB',
                    traffic: '5 TB',
                    price: '115000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=294&currency=6',
                    popular: false
                },
                {
                    memory: '16 GB',
                    vcpus: '6 vCPU',
                    ssd: '320 GB',
                    traffic: '6 TB',
                    price: '230000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=295&currency=6',
                    popular: false
                },
                {
                    memory: '32 GB',
                    vcpus: '8 vCPU',
                    ssd: '640 GB',
                    traffic: '7 TB',
                    price: '460000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=296&currency=6',
                    popular: false
                },
                {
                    memory: '48 GB',
                    vcpus: '12 vCPU',
                    ssd: '960 GB',
                    traffic: '8 TB',
                    price: '700000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=297&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '16 vCPU',
                    ssd: '1280 GB',
                    traffic: '9 TB',
                    price: '950000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=298&currency=6',
                    popular: false
                },
                {
                    memory: '96 GB',
                    vcpus: '20 vCPU',
                    ssd: '1920 GB',
                    traffic: '10 TB',
                    price: '1400000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=299&currency=6',
                    popular: false
                },
                {
                    memory: '128 GB',
                    vcpus: '24 vCPU',
                    ssd: '2560 GB',
                    traffic: '11 TB',
                    price: '1900000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=300&currency=6',
                    popular: false
                },
                {
                    memory: '192 GB',
                    vcpus: '32 vCPU',
                    ssd: '3840 GB',
                    traffic: '12 TB',
                    price: '2800000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=301&currency=6',
                    popular: false
                },
            ]
        },
        {
            id: 2,
            cloud: 'CPU Dedicada',
            description: '¿Busca un rendimiento mayor? Pruebe nuestros planes de CPU’s dedicados a proveer un servicio único por el precio más conveniente. Desarrolle sus proyectos sin límites.',
            types: [
                {
                    memory: '4 GB',
                    vcpus: '2 vCPU',
                    ssd: '25 GB',
                    traffic: '6 TB',
                    price: '150000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=313&currency=6',
                    popular: true
                },
                {
                    memory: '8 GB',
                    vcpus: '4 vCPU',
                    ssd: '50 GB',
                    traffic: '7 TB',
                    price: '300000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=314&currency=6',
                    popular: false
                },
                {
                    memory: '16 GB',
                    vcpus: '8 vCPU',
                    ssd: '100 GB',
                    traffic: '8 TB',
                    price: '600000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=315&currency=6',
                    popular: false
                },
                {
                    memory: '32 GB',
                    vcpus: '16 vCPU',
                    ssd: '200 GB',
                    traffic: '9 TB',
                    price: '1200000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=316&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '32 vCPU',
                    ssd: '400 GB',
                    traffic: '10 TB',
                    price: '2400000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=317&currency=6',
                    popular: false
                },
            ]
        },
        {
            id: 3,
            cloud: 'Alta memoria',
            description: '¿Busque más capacidad de procesamiento o de almacenamiento? Revise nuestros planes enfocados en entregar la más alta capacidad de trabajo y descubra un mundo de beneficios que le permitirán un mejor desempeño.',
            types: [
                {
                    memory: '16 GB',
                    vcpus: '1 vCPU',
                    ssd: '25 GB',
                    traffic: '6 TB',
                    price: '150000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=323&currency=6',
                    popular: true
                },
                {
                    memory: '32 GB',
                    vcpus: '2 vCPU',
                    ssd: '50 GB',
                    traffic: '7 TB',
                    price: '300000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=324&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '4 vCPU',
                    ssd: '80 GB',
                    traffic: '8 TB',
                    price: '600000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=325&currency=6',
                    popular: false
                },
                {
                    memory: '128 GB',
                    vcpus: '8 vCPU',
                    ssd: '160 GB',
                    traffic: '9 TB',
                    price: '1200000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=326&currency=6',
                    popular: false
                },
                {
                    memory: '192 GB',
                    vcpus: '16 vCPU',
                    ssd: '320 GB',
                    traffic: '10 TB',
                    price: '2400000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=327&currency=6',
                    popular: false
                },
            ]
        }
    ],
    clouds_with_backup: [
        {
            id: 1,
            cloud: 'Estándar + Backup',
            description: 'Disponemos de una variedad extensa de planes diseñados para abarcar la mayoría de las necesidades en un mercado que está siempre cambiando. Reconocemos que cada cliente tiene necesidades únicas y nos esforzamos por satisfacerlas todas.',
            types: [
                {
                    memory: '1 GB',
                    vcpus: '1 vCPU',
                    ssd: '20 GB',
                    traffic: '1 TB',
                    backup: "Incluido",
                    price: '14040',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=302&currency=6',
                    popular: false
                },
                {
                    memory: '2 GB',
                    vcpus: '1 vCPU',
                    ssd: '50 GB',
                    traffic: '2 TB',
                    backup: "Incluido",
                    price: '33600',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=303&currency=6',
                    popular: false
                },
                {
                    memory: '4 GB',
                    vcpus: '2 vCPU',
                    ssd: '80 GB',
                    traffic: '4 TB',
                    backup: "Incluido",
                    price: '67200',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=304&currency=6',
                    popular: true
                },
                {
                    memory: '8 GB',
                    vcpus: '4 vCPU',
                    ssd: '160 GB',
                    traffic: '5 TB',
                    backup: "Incluido",
                    price: '138000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=305&currency=6',
                    popular: false
                },
                {
                    memory: '16 GB',
                    vcpus: '6 vCPU',
                    ssd: '320 GB',
                    traffic: '6 TB',
                    backup: "Incluido",
                    price: '276000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=306&currency=6',
                    popular: false
                },
                {
                    memory: '32 GB',
                    vcpus: '8 vCPU',
                    ssd: '640 GB',
                    traffic: '7 TB',
                    backup: "Incluido",
                    price: '552000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=307&currency=6',
                    popular: false
                },
                {
                    memory: '48 GB',
                    vcpus: '12 vCPU',
                    ssd: '960 GB',
                    traffic: '8 TB',
                    backup: "Incluido",
                    price: '840000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=308&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '16 vCPU',
                    ssd: '1280 GB',
                    traffic: '9 TB',
                    backup: "Incluido",
                    price: '1140000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=309&currency=6',
                    popular: false
                },
                {
                    memory: '96 GB',
                    vcpus: '20 vCPU',
                    ssd: '1920 GB',
                    traffic: '10 TB',
                    backup: "Incluido",
                    price: '1680000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=310&currency=6',
                    popular: false
                },
                {
                    memory: '128 GB',
                    vcpus: '24 vCPU',
                    ssd: '2560 GB',
                    traffic: '11 TB',
                    backup: "Incluido",
                    price: '2280000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=311&currency=6',
                    popular: false
                },
                {
                    memory: '192 GB',
                    vcpus: '32 vCPU',
                    ssd: '3840 GB',
                    traffic: '12 TB',
                    backup: "Incluido",
                    price: '3360000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=312&currency=6',
                    popular: false
                },
            ]
        },
        {
            id: 2,
            cloud: 'CPU Dedicada + Backup',
            description: '¿Deseas obtener un rendimiento superior? Experimenta nuestros planes de CPU diseñados para ofrecer un servicio excepcional a un precio asequible. Desarrolla tus proyectos sin restricciones.',
            types: [
                {
                    memory: '4 GB',
                    vcpus: '2 vCPU',
                    ssd: '25 GB',
                    traffic: '6 TB',
                    backup: "Incluido",
                    price: '180000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=318&currency=6',
                    popular: true
                },
                {
                    memory: '8 GB',
                    vcpus: '4 vCPU',
                    ssd: '50 GB',
                    traffic: '7 TB',
                    backup: "Incluido",
                    price: '360000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=319&currency=6',
                    popular: false
                },
                {
                    memory: '16 GB',
                    vcpus: '8 vCPU',
                    ssd: '100 GB',
                    traffic: '8 TB',
                    backup: "Incluido",
                    price: '720000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=320&currency=6',
                    popular: false
                },
                {
                    memory: '32 GB',
                    vcpus: '16 vCPU',
                    ssd: '200 GB',
                    traffic: '9 TB',
                    backup: "Incluido",
                    price: '1440000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=321&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '32 vCPU',
                    ssd: '400 GB',
                    traffic: '10 TB',
                    backup: "Incluido",
                    price: '2880000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=322&currency=6',
                    popular: false
                },
            ]
        },
        {
            id: 3,
            cloud: 'Alta memoria + Backup',
            description: '¿Necesitas mayor capacidad de procesamiento o almacenamiento? Explora nuestros planes centrados en proporcionar la máxima capacidad de trabajo y descubre una variedad de beneficios que mejorarán tu rendimiento significativamente.',
            types: [
                {
                    memory: '16 GB',
                    vcpus: '1 vCPU',
                    ssd: '25 GB',
                    traffic: '6 TB',
                    backup: "Incluido",
                    price: '180000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=328&currency=6',
                    popular: true
                },
                {
                    memory: '32 GB',
                    vcpus: '2 vCPU',
                    ssd: '50 GB',
                    traffic: '7 TB',
                    backup: "Incluido",
                    price: '360000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=329&currency=6',
                    popular: false
                },
                {
                    memory: '64 GB',
                    vcpus: '4 vCPU',
                    ssd: '80 GB',
                    traffic: '8 TB',
                    backup: "Incluido",
                    price: '720000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=330&currency=6',
                    popular: false
                },
                {
                    memory: '128 GB',
                    vcpus: '8 vCPU',
                    ssd: '160 GB',
                    traffic: '9 TB',
                    backup: "Incluido",
                    price: '1440000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=331&currency=6',
                    popular: false
                },
                {
                    memory: '192 GB',
                    vcpus: '16 vCPU',
                    ssd: '320 GB',
                    traffic: '10 TB',
                    backup: "Incluido",
                    price: '2880000',
                    url: 'https://panel.bluehosting.host/cart.php?a=add&pid=332&currency=6',
                    popular: false
                },
            ]
        }
    ],
    web_protection: [
        {
            id: 1,
            name: 'Seguridad sitio web',
            sub: 'Potencia premium para comenzar su experiencia digital.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=288&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-lightning.svg',
                    text: 'Tiempo de respuesta <b>12 horas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-storage.svg',
                    text: 'Remoción de malware <b>ilimitada</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-web.svg',
                    text: 'Prevención de malware <b>WAF</b>'
                },
            ],
            periods: [
                {
                    period: '1 año',
                    price: '170000',
                },
                {
                    period: '2 años',
                    price: '155000',
                },
                {
                    period: '3 años',
                    price: '135000',
                }
            ]
        }
    ],
    ssl_certificates: [
        {
            id: 1,
            name: 'SSL un Dominio',
            sub: 'Más poder y flexibilidad para varios sitios web.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=285&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Asegurar <b>1 dominio</b> principal de la <br>cuenta'
                },
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: 'Validación de <b>dominio</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: 'Sólido cifrado <b>SHA2</b> y encriptación de <b>2048 bits</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: '<b>Incrementa el posicionamiento</b> de tu sitio en Google'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Garantía de <b>USD $250.000</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-chart.svg',
                    text: 'Reemisión <b>gratuita</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-protaction.svg',
                    text: '<b>30 días</b> de reembolso'
                },
            ],
            periods: [
                {
                    period: '1 año',
                    price: '279000',
                }
            ]
        },
        {
            id: 2,
            name: 'SSL Wildcard',
            sub: 'Doble de potencia para sitios complejos y alto tráfico.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=286&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Asegurar <b>1 dominio</b> principal y todos sus subdominios'
                },
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: 'Validación de <b>dominio</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: 'Sólido cifrado <b>SHA2</b> y encriptación de <b>2048 bits</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: '<b>Incrementa el posicionamiento</b> de tu sitio en Google'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Garantía de <b>USD $10.000</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-chart.svg',
                    text: 'Reemisión <b>gratuita</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-protaction.svg',
                    text: '<b>30 días</b> de reembolso'
                },
            ],
            periods: [
                {
                    period: '1 año',
                    price: '459000',
                }
            ]
        },
        {
            id: 3,
            name: 'EV SSL',
            sub: 'Más poder y flexibilidad para varios sitios web.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=287&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Asegurar <b>1 dominio</b> principal de la <br>cuenta'
                },
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: 'Validación de <b>organización</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: 'Sólido cifrado <b>SHA2</b> y encriptación de <b>2048 bits</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: '<b>Incrementa el posicionamiento</b> de tu sitio en Google'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Garantía de <b>USD $1.750.000</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-chart.svg',
                    text: 'Reemisión <b>gratuita</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-protaction.svg',
                    text: '<b>30 días</b> de reembolso'
                },
            ],
            periods: [
                {
                    period: '1 año',
                    price: '1167000',
                }
            ]
        },
    ],
    resellers: [
        {
            id: 1,
            name: 'Power',
            sub: 'Más poder y almacenamiento para su empresa.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=11&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: '<b>50 GB</b> espacio web - Cuentas de hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: '<b>300 GB</b> Límite de transferencia de datos mensuales - Cuentas hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: 'Cuentas de hosting <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Cuentas de correo <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-lamp.svg',
                    text: 'Sub-dominios <b>ilimitados</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Bases de datos MySQL / Postgre SQL <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: '<b>10 GB</b> transferencia mensual - Cuenta principal'
                },
            ],
            periods: [
                {
                    price: '55000',
                },
                {
                    period: ' 6 meses',
                    price: '50000',
                },
                {
                    period: ' un año',
                    price: '45000',
                },
            ]
        },
        {
            id: 2,
            name: 'Full',
            sub: 'El doble de espacio.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=13&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: '<b>100 GB</b> espacio web - Cuentas de hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: '<b>500 GB</b> Límite de transferencia de datos mensuales - Cuentas hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: 'Cuentas de hosting <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Cuentas de correo <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-lamp.svg',
                    text: 'Sub-dominios <b>ilimitados</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Bases de datos MySQL / Postgre SQL <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: '<b>10 GB</b> transferencia mensual - Cuenta principal'
                },
            ],
            periods: [
                {
                    price: '95000',
                },
                {
                    period: ' 6 meses',
                    price: '90000',
                },
                {
                    period: ' un año',
                    price: '85000',
                },
            ]
        },
        {
            id: 3,
            name: 'Ultra',
            sub: 'Tres veces más poderoso y soporte Hero exclusivo.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=14&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: '<b>150 GB</b> espacio web - Cuentas de hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: '<b>700 GB</b> Límite de transferencia de datos mensuales - Cuentas hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: 'Cuentas de hosting <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Cuentas de correo <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-lamp.svg',
                    text: 'Sub-dominios <b>ilimitados</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Bases de datos MySQL / Postgre SQL <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: '<b>10 GB</b> transferencia mensual - Cuenta principal'
                },
            ],
            periods: [
                {
                    price: '140000',
                },
                {
                    period: ' 6 meses',
                    price: '135000',
                },
                {
                    period: ' un año',
                    price: '130000',
                },
            ]
        },
        {
            id: 4,
            name: 'Ultimate',
            sub: 'Cuatro veces más poder y recursos dedicados.',
            url: 'https://panel.bluehosting.host/cart.php?a=add&pid=15&currency=6',
            features: [
                {
                    icon_url: '/assets/images/icons/icon-basket.svg',
                    text: '<b>200 GB</b> espacio web - Cuentas de hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-box.svg',
                    text: '<b>1.200 GB</b> Límite de transferencia de datos mensuales - Cuentas hosting'
                },
                {
                    icon_url: '/assets/images/icons/icon-key.svg',
                    text: 'Cuentas de hosting <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-sound.svg',
                    text: 'Cuentas de correo <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-lamp.svg',
                    text: 'Sub-dominios <b>ilimitados</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: 'Bases de datos MySQL / Postgre SQL <b>ilimitadas</b>'
                },
                {
                    icon_url: '/assets/images/icons/icon-home.svg',
                    text: '<b>10 GB</b> transferencia mensual - Cuenta principal'
                },
            ],
            periods: [
                {
                    price: '185000',
                },
                {
                    period: ' 6 meses',
                    price: '180000',
                },
                {
                    period: ' un año',
                    price: '175000',
                },
            ]
        },
    ],
    links: {
        personas: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=1&currency=6',
            dialogs: {
                html: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6',
                ecommerce: 'https://panel.bluehosting.host/cart.php?a=add&pid=255&currency=6',
                blog: 'https://panel.bluehosting.host/cart.php?a=add&pid=84&currency=6',
                app: 'https://panel.bluehosting.host/cart.php?a=add&pid=266&currency=6'
            }
        },
        pymes: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=24&currency=6'
        },
        wordpress: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=28&currency=6'
        },
        empresas: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=70&currency=6'
        },
        cloud: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=68&currency=6'
        },
        ssl: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=76&currency=6'
        },
        reseller: {
            banner: 'https://panel.bluehosting.host/cart.php?gid=6&currency=6'
        }
    },
    domains: [
        {
            tld: '.cl',
            price: '60000',
            info: '.CL se utiliza para entidades relacionadas con Chile. Cualquier persona puede registrar este dominio.'
        },
        {
            tld: '.pe',
            price: '280000',
            info: '.PE se utiliza para entidades relacionadas con Perú. Cualquier persona puede registrar este dominio.'
        },
        {
            tld: '.com.pe',
            price: '280000',
            info: '.COM.PE se utiliza para entidades comerciales relacionadas con Perú.'
        },
        {
            tld: '.net.pe',
            price: '280000',
            info: '.NET.PE se utiliza para entidades comerciales relacionadas con Perú.'
        },
        {
            tld: '.co',
            price: '190000',
            info: '.CO se utiliza para entidades relacionadas con Colombia. Cualquier persona puede registrar este dominio.'
        },
        {
            tld: '.com.co',
            price: '65000',
            info: '.COM.CO se utiliza para entidades comerciales relacionadas con Colombia.'
        },
        {
            tld: '.net.co',
            price: '65000',
            info: '.NET.CO se utiliza para entidades comerciales relacionadas con Colombia.'
        },
        {
            tld: '.com.ar',
            price: '280000',
            info: '.COM.AR se utiliza para entidades comerciales relacionadas con Argentina.'
        },
        {
            tld: '.com.mx',
            price: '100000',
            info: '.COM.MX Se utiliza para entidades comerciales relacionadas con México.'
        },
        /*{
            tld: '.com.br',
            price: '110000',
            info: '.COM.BR se utiliza para entidades comerciales relacionadas con Brasil'
        },*/
        {
            tld: '.com',
            price: '56000',
            info: '.COM significa comercial. Los dominios .com son normalmente registrados por las empresas comerciales, pero cualquier persona puede registrar dominios .COM.'
        },
        {
            tld: '.org',
            price: '56000',
            info: '.ORG significa organización. Dominios .ORG tradicionalmente han sido registradas por organizaciones sin fines de lucro. Sin embargo, cualquier persona puede registrar dominios .ORG para cualquier propósito'
        },
        {
            tld: '.net',
            price: '56000',
            info: 'NET significa network. Los dominios .NET tradicionalmente han sido registrados por proveedores de servicios de Internet. Sin embargo, cualquier persona puede registrar dominios .NET para cualquier propósito.'
        },
        {
            tld: '.pro',
            price: '90000',
            info: '.PRO significa profesional. Dominios .PRO tradicionalmente han sido registradas por profesionales para publicar sus servicios. Sin embargo cualquier persona puede registrar dominios .PRO para cualquier proposito.'
        },
        {
            tld: '.ltda',
            price: '260000',
            info: '.LTDA está destinada especificamente a empresas en Chile y Latinoamérica que formalmente estén clasificadas como sociedad de responsabilidad limitada en su razón social. '
        },
        {
            tld: '.io',
            price: '280000',
            info: '.IO es la extensión de dominio geográfico (ccTLD) para los Territorios Británicos del Océano Índico, formados por todo el archipiélago de Chagos de 55 islas. Actualmente, no hay habitantes indígenas en estas islas.'
        },
        {
            tld: '.blog',
            price: '145000',
            info: '.BLOG es comercializado para identificar blogs personales o corporativos de distintos temas; Actualmente pueden ser registrado con cualquier proposito.'
        },
        {
            tld: '.us',
            price: '56000',
            info: '.US significa United States. Cualquier persona puede registrar dominios .US para cualquier propósito'
        },
        {
            tld: '.info',
            price: '56000',
            info: '.INFO significa información. Cualquier persona puede registrar dominios .INFO para cualquier propósito'
        },
        {
            tld: '.name',
            price: '56000',
            info: '.NAME en una palabra en ingles que significa Nombre. Cualquier persona puede registrar dominios .NAME para cualquier propósito'
        },
        {
            tld: '.tv',
            price: '215000',
            info: '.TV es comercializado para ser usado en sitios relacionados con la televisión o vídeos; pueden ser registrados con cualquier propósito'
        },
        {
            tld: '.biz',
            price: '56000',
            info: '.BIZ significa Business, palabra en ingles que significa Negocio. Cualquier persona puede registrar dominios .BIZ para cualquier propósito'
        },
    ],
    payment: [
        {
            title: 'Tarjeta de crédito',
            subtitle: 'Adquiera su plan de hosting cancelando con su tarjeta de crédito.',
            anchor: 'Activación inmediata',
            methods: [
                {
                    img_url: '/assets/images/payment/VISA.png',
                    alt: 'VISA'
                },
                {
                    img_url: '/assets/images/payment/mastercard.png',
                    alt: 'Mastercard'
                },
                {
                    img_url: '/assets/images/payment/AMERICAN-EXPRESS.png',
                    alt: 'AmericanExpress'
                },
                {
                    img_url: '/assets/images/payment/DINERS-CLUB.png',
                    alt: 'DinersClub'
                },
                {
                    img_url: '/assets/images/payment/CODENSA.png',
                    alt: 'Codensa'
                }
            ]
        },
        {
            title: 'Banco de Bogotá',
            subtitle: 'Haga uso con nosotros de su tarjeta débito y cancele en segundos.',
            anchor: 'Activación inmediata',
            methods: [
                {
                    img_url: '/assets/images/payment/BANCO-BOGOTA.png',
                    alt: 'BancoBogota'
                }
            ]
        },
        {
            title: 'Crédito Bancario PSE',
            subtitle: '¡No se preocupe por como ha de pagar su plan Web Hosting! Con PSE puede hacerlo desde cualquier entidad bancaria.',
            anchor: 'Activación inmediata',
            methods: [
                {
                    img_url: '/assets/images/payment/PSE.png',
                    alt: 'PSE'
                }
            ]
        },
        {
            title: 'Pago en efectivo',
            subtitle: 'Si desea realizar su pago en efectivo; bien puede hacerlo a través de:',
            anchor: 'Se registra el pago pasadas las 24 horas',
            methods: [
                {
                    img_url: '/assets/images/payment/BALOTO.png',
                    alt: 'Baloto'
                },
                {
                    img_url: '/assets/images/payment/PAGA-TODO-PARA-TODO.png',
                    alt: 'PagaTodoParaTodo'
                },
                {
                    img_url: '/assets/images/payment/APUESTAS-CUCUTA.png',
                    alt: 'ApuestasCucuta'
                },
                {
                    img_url: '/assets/images/payment/GANA.png',
                    alt: 'Gana'
                },
                {
                    img_url: '/assets/images/payment/GANA-GANA.png',
                    alt: 'GanaGana'
                },
                {
                    img_url: '/assets/images/payment/SU-CHANCE.png',
                    alt: 'SuChance'
                },
                {
                    img_url: '/assets/images/payment/ACERTEMOS.png',
                    alt: 'Acertemos'
                },
                {
                    img_url: '/assets/images/payment/LA-PERLA.png',
                    alt: 'LaPerla'
                },
                {
                    img_url: '/assets/images/payment/APUESTAS-UNIDAD.png',
                    alt: 'ApuestasUnidad'
                },
                {
                    img_url: '/assets/images/payment/JER.png',
                    alt: 'JER'
                },
                {
                    img_url: '/assets/images/payment/EFECTY.png',
                    alt: 'Efecty'
                }
            ]
        },
        {
            title: 'Pago en bancos',
            subtitle: 'Cancele su plan de hosting por medio de su banco favorito.',
            anchor: 'Se registra el pago pasadas las 24 horas después del depósito.',
            methods: [
                {
                    img_url: '/assets/images/payment/BANCO-BOGOTA.png',
                    alt: 'BancoBogota'
                },
                {
                    img_url: '/assets/images/payment/BANCOLOMBIA.png',
                    alt: 'Bancolombia'
                },
                {
                    img_url: '/assets/images/payment/DAVIVIENDA.png',
                    alt: 'Davivienda'
                }
            ]
        },
        {
            title: 'PayPal',
            subtitle: 'Paga tu plan de hosting con Paypal.',
            anchor: 'Activación inmediata',
            methods: [
                {
                    img_url: '/assets/images/payment/paypal.png',
                    alt: 'PAYPAL'
                }
            ]
        }
    ],
    blackfriday: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Sus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesita para administrar su sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverá a preocuparse por la cantidad de contenido en su sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de su sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador usted podrá utilizar miles de aplicaciones con solo un clic en su sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos sus datos, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Cree la cantidad de bases de datos que necesite para almacenar toda la información que sus clientes le entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a sus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=6&promocode=promo-blue-e20',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '150000',
                price_actual: '120000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '100000',
                        price_actual: '80000',
                    }, {
                        period: '3 años',
                        price_pre: '90000',
                        price_actual: '72000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6&promocode=promo-blue-e20',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '170000',
                price_actual: '136000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '120000',
                        price_actual: '96000',
                    }, {
                        period: '3 años',
                        price_pre: '110000',
                        price_actual: '88000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición </b>',
                alt: 'Transferencia de datos sin medición'
            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'
            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    cyberdayNew: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Sus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesita para administrar su sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverá a preocuparse por la cantidad de contenido en su sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de su sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador usted podrá utilizar miles de aplicaciones con solo un clic en su sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos sus datos, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Cree la cantidad de bases de datos que necesite para almacenar toda la información que sus clientes le entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a sus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=6&promocode=cyberday',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '150000',
                price_actual: '120000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '100000',
                        price_actual: '80000',
                    }, {
                        period: '3 años',
                        price_pre: '90000',
                        price_actual: '72000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6&promocode=cyberday',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '170000',
                price_actual: '136000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '120000',
                        price_actual: '96000',
                    }, {
                        period: '3 años',
                        price_pre: '110000',
                        price_actual: '88000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición</b>',
                alt: 'Transferencia de datos sin medición'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'

            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    cyberMonday: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Tus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesitas para administrar tu sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverás a preocuparte por la cantidad de contenido en tu sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de tu sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador podrás utilizar miles de aplicaciones con solo un clic en tu sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos tu datos por ti, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Crea la cantidad de bases de datos que necesites para almacenar toda la información que tus clientes te entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a tus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=1&promocode=cybermonday',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '34900',
                price_actual: '27920',
                period: [
                    {
                        period: '2 años',
                        price_pre: '22900',
                        price_actual: '18320',
                    }, {
                        period: '3 años',
                        price_pre: '20900',
                        price_actual: '16720',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=1&promocode=cybermonday',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '38900',
                price_actual: '31120',
                period: [
                    {
                        period: '2 años',
                        price_pre: '26900',
                        price_actual: '21520',
                    }, {
                        period: '3 años',
                        price_pre: '24900',
                        price_actual: '19920',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .CL gratis</b> por 1 año',
                        alt: 'Dominio .CL gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición</b>',
                alt: 'Transferencia de datos sin medición'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'

            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    hotsale: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Sus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesita para administrar su sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverá a preocuparse por la cantidad de contenido en su sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de su sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador usted podrá utilizar miles de aplicaciones con solo un clic en su sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos sus datos, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Cree la cantidad de bases de datos que necesite para almacenar toda la información que sus clientes le entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a sus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=6&promocode=hotsale',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '150000',
                price_actual: '120000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '100000',
                        price_actual: '80000',
                    }, {
                        period: '3 años',
                        price_pre: '90000',
                        price_actual: '72000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6&promocode=hotsale',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '170000',
                price_actual: '136000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '120000',
                        price_actual: '96000',
                    }, {
                        period: '3 años',
                        price_pre: '110000',
                        price_actual: '88000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición </b>',
                alt: 'Transferencia de datos sin medición'

            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'
            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    promotioms_holiday: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Tus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesitas para administrar tu sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverás a preocuparte por la cantidad de contenido en tu sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de tu sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador podrás utilizar miles de aplicaciones con solo un clic en tu sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos tu datos por ti, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Crea la cantidad de bases de datos que necesites para almacenar toda la información que tus clientes te entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a tus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=1&promocode=fiestaspatrias&tld=.cl',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '34900',
                price_actual: '27920',
                period: [
                    {
                        period: '2 años',
                        price_pre: '22900',
                        price_actual: '18320',
                    }, {
                        period: '3 años',
                        price_pre: '20900',
                        price_actual: '16720',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=1&promocode=fiestaspatrias&tld=.cl',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '38900',
                price_actual: '31120',
                period: [
                    {
                        period: '2 años',
                        price_pre: '26900',
                        price_actual: '21520',
                    }, {
                        period: '3 años',
                        price_pre: '24900',
                        price_actual: '19920',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .CL gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon_sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon_ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon_basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon_lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon_box',
                text: 'Transferencia de datos <b>sin medición</b>',
                alt: 'Transferencia de datos sin medición'
            }, {
                icon: 'icon_install_wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon_sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'

            }, {
                icon: 'icon_lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    felicesFiestas: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Sus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesita para administrar su sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverá a preocuparse por la cantidad de contenido en su sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de su sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador usted podrá utilizar miles de aplicaciones con solo un clic en su sitio web. Incluido en todas nuestras Promociones Web Hosting.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos sus datos, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Crea la cantidad de bases de datos que necesite para almacenar toda la información que sus clientes le entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a sus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=6&tld=.co&promocode=navidad',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '150000',
                price_actual: '120000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '100000',
                        price_actual: '80000',
                    }, {
                        period: '3 años',
                        price_pre: '90000',
                        price_actual: '72000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=6&tld=.co&promocode=navidad',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '170000',
                price_actual: '136000',
                period: [
                    {
                        period: '2 años',
                        price_pre: '120000',
                        price_actual: '96000',
                    }, {
                        period: '3 años',
                        price_pre: '110000',
                        price_actual: '88000',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición</b>',
                alt: 'Transferencia de datos sin medición'

            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'
            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    cyberday: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Tus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesitas para administrar tu sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverás a preocuparte por la cantidad de contenido en tu sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de tu sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador podrás utilizar miles de aplicaciones con solo un clic en tu sitio web.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos tu datos por ti, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Crea la cantidad de bases de datos que necesites para almacenar toda la información que tus clientes te entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a tus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=5&promocode=promo-blue-e20',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '170',
                price_actual: '136',
                period: [
                    {
                        period: '2 años',
                        price_pre: '110',
                        price_actual: '88',
                    }, {
                        period: '3 años',
                        price_pre: '100',
                        price_actual: '80',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=5&promocode=promo-blue-e20',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '190',
                price_actual: '152',
                period: [
                    {
                        period: '2 años',
                        price_pre: '130',
                        price_actual: '104',
                    }, {
                        period: '3 años',
                        price_pre: '120',
                        price_actual: '96',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición</b>',
                alt: 'Transferencia de datos sin medición'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'

            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
    promo_hostings: {
        columns: [
            {
                ttl: 'Discos <b>SSD</b>',
                icon: 'ssd',
                alt: 'Discos SSD',
                text: 'Contamos con tecnología de discos sólidos en todos nuestros servicios. Tus datos tendrán un rendimiento más rápido y confiable.'
            }, {
                ttl: '<b>CPanel</b>',
                icon: 'spanel',
                alt: 'CPanel',
                text: 'El panel de control más usado en el mundo. Con todos los recursos que necesitas para administrar tu sitio.'
            }, {
                ttl: 'Almacenamiento <b>SSD ilimitado</b>',
                icon: 'ssd_ilimitado',
                alt: 'Almacenamiento SSD ilimitado',
                text: 'Con nuestra política de almacenamiento no volverás a preocuparte por la cantidad de contenido en tu sitio web.'
            }, {
                ttl: '<b>Respaldos</b>',
                icon: 'respaldos',
                alt: 'Respaldos',
                text: 'Realizamos copias de seguridad de tu sitio web para restaurarlo en caso de que surjan problemas.'
            }, {
                ttl: 'Instalador de <b>Apps</b>',
                icon: 'apps',
                alt: 'Instalador de Apps',
                text: 'Gracias a nuestro instalador podrás utilizar miles de aplicaciones con solo un clic en tu sitio web. Incluido en todas nuestras Promociones Web Hosting.'
            }, {
                ttl: 'Migración <b>gratuita</b>',
                icon: 'migration2',
                alt: 'Migración gratuita',
                text: 'Migramos de cualquier proveedor todos tu datos por ti, de manera gratuita y sin complicaciones.'
            }, {
                ttl: 'Base de datos <b>ilimitadas</b>',
                icon: 'database',
                alt: 'Base de datos ilimitadas',
                text: 'Crea la cantidad de bases de datos que necesites para almacenar toda la información que tus clientes te entreguen.'
            }, {
                ttl: 'SSL <b>Gratis</b>',
                icon: 'ssl2',
                alt: 'SSL Gratis',
                text: 'Nuestros planes cuentan con un certificado ssl, ofreciéndole más seguridad y confianza a tus clientes al garantizar el cifrado de todas sus interacciones.'
            }
        ],
        plans: [
            {
                name: 'power',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=66&currency=5&promocode=fiestaspatrias',
                sub: 'Recomendado para requerimientos puntuales',
                price_pre: '170',
                price_actual: '136',
                period: [
                    {
                        period: '2 años',
                        price_pre: '110',
                        price_actual: '88',
                    }, {
                        period: '3 años',
                        price_pre: '100',
                        price_actual: '80',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>50% más poderoso</b> que los hosting comunes',
                        alt: 'Hasta 50% más poderoso que los hosting comunes'
                    }, {
                        icon: 'icon-web',
                        text: '1 sitio <b>web</b>',
                        alt: '1 sitio web'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }
                ]
            }, {
                name: 'full',
                url: 'https://panel.bluehosting.host/cart.php?a=add&pid=68&currency=5&promocode=fiestaspatrias',
                sub: 'Ideal para proyectos en crecimiento',
                price_pre: '190',
                price_actual: '152',
                period: [
                    {
                        period: '2 años',
                        price_pre: '130',
                        price_actual: '104',
                    }, {
                        period: '3 años',
                        price_pre: '120',
                        price_actual: '96',
                    }
                ],
                plan_list: [
                    {
                        icon: 'icon-lightning',
                        text: 'Hasta <b>40% más poderoso</b> que plan Emprendedores Power',
                        alt: 'Hasta 40% más poderoso que plan Emprendedores Power'
                    }, {
                        icon: 'icon-web',
                        text: 'Sitios <b>web ilimitados</b>',
                        alt: 'Sitios web ilimitados'
                    }, {
                        icon: 'icon-storage',
                        text: 'Espacio almacenamiento <b>SSD Ilimitado</b>',
                        alt: 'Espacio almacenamiento SSD Ilimitado'
                    }, {
                        icon: 'icon-domen',
                        text: '<b>Dominio .COM gratis</b> por 1 año',
                        alt: 'Dominio .COM gratis por 1 año'
                    }
                ]
            }
        ],
        allPlansList: [
            {
                icon: 'icon_home2',
                text: 'Cuentas de correo <b>Ilimitadas</b>',
                alt: 'Cuentas de correo Ilimitadas'
            }, {
                icon: 'icon-key',
                text: 'Antispam Premium <b>95% efectividad</b>',
                alt: 'Antispam Premium 95% efectividad'
            }, {
                icon: 'icon-web2',
                text: '<b>Creador</b> de sitios web',
                alt: 'Creador de sitios web'
            }, {
                icon: 'icon-sound',
                text: '<b>Cpanel</b>',
                alt: 'Cpanel'
            }, {
                icon: 'icon-ssl',
                text: 'Web Aplication <b>Firewall Premium</b>',
                alt: 'Web Aplication Firewall Premium'
            }, {
                icon: 'icon-basket',
                text: 'Base de datos <b>Ilimitada</b>',
                alt: 'Base de datos Ilimitada'
            }, {
                icon: 'icon-lamp',
                text: 'Subdominios  <b>Ilimitados</b>',
                alt: 'Subdominios Ilimitados'
            }, {
                icon: 'icon-install-wordpress',
                text: 'Migración <b>gratuita</b>',
                alt: 'Migración gratuita'
            }, {
                icon: 'icon-box',
                text: 'Transferencia de datos <b>sin medición </b>',
                alt: 'Transferencia de datos sin medición'

            }, {
                icon: 'icon-sound',
                text: '<b>Certificado SSL</b>',
                alt: 'Certificado SSL'
            }, {
                icon: 'icon-lamp',
                text: '<b>Instalador</b> de apps',
                alt: 'Instalador de apps'
            }
        ]
    },
};
