import { Component, OnInit, Inject, PLATFORM_ID, ElementRef, HostListener, ViewChild, Input } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogCustomMenuComponent } from '../dialogs/dialog-custom-menu/dialog-custom-menu.component';
import { URL } from '../../../environments/environment';
import { DataPage } from '../../services/data-page';
import { LocaleService } from '../../services/locale.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    @Input() promotionStrip: boolean;
    @ViewChild('triggerDialog', { static: true }) triggerDialog: ElementRef;

    isTablet: boolean = false;
    isMobile: boolean = false;
    isDesktop: boolean = false;

    activeMenu: boolean = false;
    activeChats: boolean = false;
    activeCountry: boolean = false;
    activeContacts: boolean = false;
    isFadeIn: boolean = false;
    isOpenDialog: boolean = false;
    // mobyle menu
    isActiveMobyleMenu1: boolean = true;
    isActiveMobyleMenu2: boolean = false;
    isActiveMobyleMenu3: boolean = false;
    isActiveDropdownSupport: boolean = false;
    isActiveMenuVPS: boolean = false;

    idButtonContact: string = 'bh-contactsales';

    URL = URL;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject(DOCUMENT) private _document: Document,
        private _el: ElementRef,
        private _router: Router,
        public dialog: MatDialog,
        private _dataPage: DataPage,
        private localeService: LocaleService
    ) { }

    @HostListener('window:scroll')

    onScroll(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.checkHeaderPosition();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isTablet = (window.innerWidth > 1200 || window.innerWidth <= 992) ? false : true;
        this.isMobile = window.innerWidth > 992 ? false : true;
        this.isDesktop = window.innerWidth > 1200 ? true : false;
    }

    ngOnInit() {
        this._dataPage.getData$().subscribe(data => this.idButtonContact = data);
        this.isTablet = (window.innerWidth > 1200 || window.innerWidth <= 992) ? false : true;
        this.isMobile = window.innerWidth > 992 ? false : true;
        this.isDesktop = window.innerWidth > 1200 ? true : false;
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    checkHeaderPosition(): void {
        const offsetY = window.scrollY;
        const headerEl = this._el.nativeElement.getElementsByTagName('header')[0] as HTMLElement;

        if (offsetY >= 54 || this._document.getElementsByTagName('html')[0].classList.contains('cdk-global-scrollblock')) {
            headerEl.classList.add('fixed');
        } else {
            headerEl.classList.remove('fixed');
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    onClickOpenMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.add('open_menu');
    }

    onClickCloseMenu() {
        const body = this._document.getElementsByTagName('body')[0];
        body.classList.remove('open_menu');
        this.isActiveMobyleMenu3 = false;
        this.isActiveMobyleMenu2 = false;
        this.isActiveDropdownSupport = false;
        this.isActiveMobyleMenu1 = true;
        this.isActiveMenuVPS = false;
    }

    menuToggle() {
        if (isPlatformBrowser(this.platformId)) {
            if (this.activeMenu) {
                this.menuClose();
            } else {
                this.isFadeIn = true;
                setTimeout(() => {
                    this.activeMenu = true;
                }, 100);
            }
        }
    }

    menuDropdownFadeIn(el) {
        const itemParent = this._findAncestorFromEl(el.target, 'dropdown');
        const upMenu = itemParent.getElementsByClassName('headerMenu__dropdown')[0] as HTMLElement;
        const headerMenu = this._document.getElementsByClassName('wrapHeader__bottom')[0] as HTMLElement;
        const bgDropdown = this._document.getElementsByClassName('dropdownBackground__inner')[0] as HTMLElement;
        itemParent.classList.add('up');
        headerMenu.classList.add('dropdownUp');

        // show and position dropdownBackground
        const origWidth = 380;
        const origHeight = 400;

        const ddWidth = upMenu.offsetWidth;
        const ddHeight = upMenu.offsetHeight;

        const scaleX = ddWidth / origWidth;
        const scaleY = ddHeight / origHeight;

        const menuRect = upMenu.getBoundingClientRect();
        const positionLeft = menuRect.left + menuRect.width / 2 - ddWidth / 2;
        const ddLeft = Math.round(Math.max(positionLeft, 10));

        bgDropdown.style.transform = 'translateX(' + ddLeft + 'px) scaleX(' + scaleX + ') scaleY(' + scaleY + ')';

        if (bgDropdown.classList.contains('noTransition')) {
            setTimeout(() => {
                bgDropdown.classList.remove('noTransition');
            }, 0);
        }
    }



    closeMenuDropdown(el) {
        const itemParent = this._findAncestorFromEl(el.target, 'dropdown');
        const itemParentUp = itemParent.classList.contains('up');

        const headerMenu = this._document.getElementsByClassName('wrapHeader__bottom')[0] as HTMLElement;
        const bgDropdown = this._document.getElementsByClassName('dropdownBackground__inner')[0] as HTMLElement;

        if (!!itemParentUp) {
            itemParent.classList.remove('up');
            headerMenu.classList.remove('dropdownUp');
            bgDropdown.classList.add('noTransition');
        }
    }

    menuClose() {
        this.isFadeIn = false;
        this.activeMenu = false;
        this.isOpenDialog = false;
    }

    openDialogCustomMenu() {
        if (window.innerWidth > 767) {
            this.dialog.open(DialogCustomMenuComponent, {
                panelClass: 'dialogCustomMenu',
                width: '268px',
                backdropClass: 'transparent',
                position: {
                    top: '26px',
                    left: this.triggerDialog.nativeElement.getBoundingClientRect().left - 54 + 'px'
                }
            });
        }
    }

    // open dropdown menu of the mobile
    openDropdownMenu(menu) {
        switch (menu) {
            case 'seguridad':
                this.isActiveMobyleMenu1 = false;
                this.isActiveMobyleMenu2 = true;
                break;
            case 'web hosting':
                this.isActiveMobyleMenu1 = false;
                this.isActiveMobyleMenu3 = true;
                break;
            case 'soporte':
                this.isActiveMobyleMenu1 = false;
                this.isActiveMobyleMenu2 = false;
                this.isActiveMobyleMenu3 = false;
                this.isActiveDropdownSupport = true;
                break;
            case 'vps':
                this.isActiveMobyleMenu1 = false;
                this.isActiveMobyleMenu2 = false;
                this.isActiveMobyleMenu3 = false;
                this.isActiveDropdownSupport = false;
                this.isActiveMenuVPS = true;
                break;
        }
    }

    // close dropdown menu of the mobile
    closeDropdownMenu(menu) {
        switch (menu) {
            case 'seguridad':
                this.isActiveMobyleMenu1 = true;
                this.isActiveMobyleMenu2 = false;
                break;
            case 'web hosting':
                this.isActiveMobyleMenu1 = true;
                this.isActiveMobyleMenu3 = false;
                break;
            case 'soporte':
                this.isActiveMobyleMenu1 = true;
                this.isActiveDropdownSupport = false;
                break;
            case 'vps':
                this.isActiveMobyleMenu1 = true;
                this.isActiveMenuVPS = false;
                break;
        }
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

}
