import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { URL } from '../../../environments/environment';

@Component({
  selector: 'app-block-logos',
  templateUrl: './block-logos.component.html'
})
export class BlockLogosComponent implements OnInit {
    public URL = URL;
    constructor() { }

    ngOnInit() {
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
