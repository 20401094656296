<!-- START firstScreen -->
<div [ngClass]="{'emprendedores': true}">
  <section class="firstScreen">
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>¡ALCANZA LA CIMA! <br> WEB HOSTING PARA <br> EMPRENDEDORES </h1>
        <div class="firstScreen__sub_ttl" i18n>Tenemos planes desde <span>{{ symbol }}</span>{{
          hosting[0].plans[0].periods[0].price | currencyLocale}} anuales, <br>
          pensados para llevarte donde tu deseas estar.</div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad bg_blue_light pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Planes</div>
    <h2 class="ttl2_black mb" i18n>Menor precio, mayor rendimiento.</h2>

    <div class="wrapCardPrice pad_buttom">

      <div class="cardPrice" *ngFor="let plan of hosting[0].plans">
        <div class="cardPrice__top">
          <div class="cardPrice_ttl"></div>
          <div class="cardPrice__ttl">{{ plan.name }}</div>
          <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
          <div *ngFor="let period of plan.periods; let i = index">
            <p class="price price_ttal">
              <span *ngIf="i > 0" class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
            </p>
            <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
            <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>

        <button id="emprendedor-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )" i18n>contratar</button>

        <ul class="card_list">
          <li *ngFor="let feature of plan.features">
            <span [innerHTML]="feature.text"></span>
            <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
          </li>
        </ul>
      </div>
    </div>
  </div>

  <app-block-domain [locale]="locale"></app-block-domain>
</section>
<!-- END -->

<!-- START menuPlans-->
<app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists" [preRender]="false" (deferLoad)="showImage1 = true; addVideoControlsForMobyle()"></app-block-list>
<!-- END blockList -->

<!-- START videoCompany -->
<app-video-company [preRender]="false" (deferLoad)="showImage2 = true" [showImage]="showImage1"></app-video-company>
<!-- END videoCompany -->

<!-- START -->
<section class="pad" [preRender]="false" (deferLoad)="showSectionSlider = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El hosting para tus necesidades</div>
    <h2 class="ttl2_black mb" i18n>¿Por qué un plan Emprendedores?</h2>

    <div class="content">
      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img2_service.svg" alt="imagen de presupuesto">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Cuida tu presupuesto con una inversión precisa</h3>
          <h4 class="ttl4" i18n>Planes convenientes</h4>
          <p i18n>Conocemos lo importante que es realizar las inversiones de forma adecuada según los momentos de cada
            empresa. Por eso, hemos pensado en distintos planes para facilitarte la toma de decisión y así invertir de
            la mejor forma tu capital.</p>
        </div>
      </div>

      <div class="flex_wrapper">
        <div class="wrap_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img20_service.svg" alt="imagen de complicaciones">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>¡No te compliques de más!</h3>
          <h4 class="ttl4" i18n>Migración gratuita</h4>
          <p i18n>Facilita las cosas desde el principio con nuestro sistema de migración gratuita. Solo con tus
            credenciales de acceso, puedes iniciar un cambio de tu antiguo proveedor a nuestros servidores sin ningún
            problema ¡Ya que lo hacemos por ti!</p>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad_top">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Aún tienes más</div>
    <h2 class="ttl2_black mb" i18n>Llega a un nivel superior</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column col-3">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img1-column-1.svg" 
                  width="131px"
                  height="134px"
                  alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Más rapidez</h3>
        <h4 class="ttl4" i18n>Instalador Apps</h4>
        <p i18n>Con un solo clic podrás instalar más de 400 apps entre las cuales encontrarás Wordpress, Joomla y
          Opencart. Y otra decena como OpenSource para E-Commerce, Blogs, Wikis y Frameworks.</p>
      </div>

      <div class="wrapCollumns_column col-3">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img17_column.svg" 
                  width="143px"
                  height="142px"
                  alt="Mejor posicionamiento">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>Mejor posicionamiento</h3>
        <h4 class="ttl4" i18n>SSL Gratuito Let’s Encrypt</h4>
        <p i18n>Todos nuestros planes cuentan con un Certificado SSL Let’s Encrypt, lo que garantiza que las
          interacciones que tendrás con tus clientes serán cifradas, ofreciéndote así más seguridad y confianza para el
          servicio que entregas.</p>
      </div>

      <div class="wrapCollumns_column col-3">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/img4_column.svg" 
                  width="130px"
                  height="131px"
                  alt="Mayor nivel">
          </ng-container>
        </div>
        <h3 class="ttl3 upper" i18n>mayor nivel de almacenamiento</h3>
        <h4 class="ttl4" i18n>Almacenamiento SSD ilimitado</h4>
        <p i18n>Con nuestros planes no volverás a preocuparte por la cantidad de contenidos que quieras almacenar. No
          importa si quieres subir fotos, videos o canciones, con BlueHosting, no volverás a limitar tus ideas.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START blockClients -->
<ng-container *ngIf="showSectionSlider">
  <app-block-clients></app-block-clients>
</ng-container>
<!-- END blockClients -->

<!-- SRTART  -->
<section class="pad_top">
  <div class="container">
    <h2 class="ttl2_black" i18n>Elige tu plan</h2>
    <h3 class="ttl3 center" i18n>Selecciona el plan óptimo para tus necesidades comerciales</h3>
  </div>
  <app-tab-plans [hosting]="hosting"></app-tab-plans>
</section>
<!-- END  -->

<!-- START blockInfo -->
<app-block-info [info]="info" (deferLoad)="showImage3 = true"></app-block-info>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey remov_pad_bottom">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro sistema</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege sino que acelera miles de sitios web y otras aplicaciones de
      internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza WAF y
            SSL entre otras herramientas para defender tu sitio.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage3">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots, que tecnologías de
            análisis convencionales no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad" [preRender]="false" (deferLoad)="showFooter = true">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cómo solicito soporte?</h3>
        <p i18n>Nuestro equipo de soporte siempre esta atento y preparado para resolver cada una de las dudas o
          inquitudes de nuestros excepcionales clientes. Puedes solicitar soporte a través las distintas plataformas que
          tenemos a tu disposición. Contamos con un chat de soporte, Call Center integrado por agentes especializados y
          un área del cliente, desde la cual puedes emitir tus tickets de soporte.</p>

        <h3 class="ttl3" i18n>¿Puedo cambiar el nombre de dominio asociado a mi plan?</h3>
        <p i18n>Sí, para efectuar el cambio del nombre de dominio asociado a tu plan debes emitir una solicitud formal a
          través de un ticket de soporte e indicar la intención de cambiar tu dominio asociado. Debes tener presente que
          este cambio implica eliminar todos los archivos de la cuenta, por lo que te recomendamos efectuar una copia de
          seguridad de tus archivos antes de solicitarlo.</p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Puedo conectarme a mi base de datos de manera remota?</h3>
        <p i18n>No, en nuestra gama de planes Emprendedor no es posible conectarse de manera remota a tu base de datos.
          Si requieres esta característica revisa nuestros planes de la gama Empresa.</p>

        <h3 class="ttl3" i18n>¿Cómo configuro mis DNS?</h3>
        <p i18n>Configurar los registros DNS es necesario para poder enlazar tu dominio con nuestro servidor; de esta
          manera, al escribir el nombre de dominio en un navegador, este se traducirá en una dirección, la cual
          pertenece a nuestro servidor. Los registros DNS para los planes WebHosting Persona son los siguientes:
        </p>
        <ol>
          <li>ns1.dnsmisitio.net</li>
          <li>ns2.dnsmisitio.net</li>
          <li>ns3.dnsmisitio.net</li>
        </ol>
      </div>
    </div>
  </div>
</section>
<!-- END block_txt_column -->


<ng-container *ngIf="showFooter">
  <!-- START block_logo -->
  <app-block-logos></app-block-logos>
  <!-- END block_logo -->

  <app-footer></app-footer>
</ng-container>