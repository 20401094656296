import { Component, Input } from '@angular/core';
import { URL } from '../../../environments/environment';


@Component({
    selector: 'app-video-company',
    templateUrl: './video-company.component.html'
})
export class VideoComponent{

    @Input() showImage? = true;
    @Input() text? = 'Contamos con una moderna instalación capaz de albergar toda la información de nuestros clientes.\
        Nuestro centro de procesamiento de datos es capaz alojar miles de sitios, sin perder confiabilidad ni seguridad.\
        Además de mantenerse en constante al crecimiento y la reconfiguración.'
    @Input() urlFoto? = '/assets/images/img-server.png'
    @Input() urlVideo? = '/assets/video/bluehosting-server.mp4'
    URL = URL;

    constructor() {}

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }


    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el: HTMLElement, cls: string): HTMLElement {
        while ((el = el.parentElement as HTMLElement) && !el.classList.contains(cls)) {}
        return el;
    }
}