import { Component, OnInit } from '@angular/core';
import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'app-dialog-syte-ecommerce',
  templateUrl: './dialog-syte-ecommerce.component.html'
})
export class DialogSyteEcommerceComponent implements OnInit {

  url;

  constructor(private localeService: LocaleService) { 
    this.url = this.localeService.getContent().links.personas.dialogs.ecommerce;
  }

  ngOnInit() {
  }

  goToUrl(): void {
    window.open(this.url, '_blank');
  }
}
