import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Info } from '../interfaces/block';

@Component({
    selector: 'app-block-info',
    templateUrl: './block-info.component.html'
})
export class BlockInfoComponent{

    @Input() info:Info;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ){
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }
}