import { Component, OnInit, Inject, HostListener, Output, PLATFORM_ID, ElementRef } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogSyteHtmlComponent } from '../shared/dialogs/dialog-syte-html/dialog-syte-html.component';
import { DialogSyteEcommerceComponent } from '../shared/dialogs/dialog-syte-ecommerce/dialog-syte-ecommerce.component';
import { DialogSyteBlogComponent } from '../shared/dialogs/dialog-syte-blog/dialog-syte-blog.component';
import { DialogSyteApplicationComponent } from '../shared/dialogs/dialog-syte-application/dialog-syte-application.component';
import { LocaleService } from '../services/locale.service';
import { MetaService } from '@ngx-meta/core';
import { URL } from '../../environments/environment';
import { ScrollToClass } from '../../../../common/scrollTo.class';
import { Info, List } from '../shared/interfaces/block';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent extends ScrollToClass implements OnInit {
    // lazy loading images
    showSectionSlider = false;
    showCharts = false;
    showFooter = false;
    showImage1 = true;
    showImage2 = false;
    showImage3 = false;
    showImage4 = false;
    showImage5 = false;
    public URL = URL;
    playVideo = false;

    isShowPlans = true;

    isActiveChartsForDesktop = true;
    isActiveProgressForMobyle = false;
    chartsIsAnimated = false;

    @Output() hosting;
    links;
    productivity;
    locale;

    symbol = '';

    endValue = 100;
    maxPerc1 = 100;
    maxPerc2 = 60;
    maxPerc3 = 51;
    maxPerc4 = 47;
    maxPerc5 = 32;
    curPerc = 0;
    perc2;
    perc3;
    perc4;
    perc5;

    public text:string = 'Contamos con una moderna instalación capaz de albergar toda la información de nuestros clientes.\
        Nuestro centro de procesamiento de datos es capaz de alojar miles de sitios, sin perder velocidad ni seguridad. \
        Además de mantenerse en constante crecimiento y actualización.'

    public info:Info = {
        title:'¿Quieres más potencial? Conoce nuestros planes Negocios',
        symbol: '',
        price: 0,
        url: '/web-hosting-negocio/.'
    }

    public lists:List = {
        sup_title:'¿Pensaste que era todo?',
        title1:'Todos los',
        title2:'planes incluyen',
        list1:[
            'Sub dominios ilimitados',
            'Instalador de Apps',
            'Anti-Spam Premiun 95% de efectividad',
            'Web Application Firewall Premium',
            'Migración gratuita',
            'Creador de sitios web',
            'cPanel'
        ],
        list2:[
            "SSL Gratuito Let's Encrypt",
            'Dominio.cl gratis por 1 año',
            'Migración gratuita',
            'Uptime 99.9%'
        ]
    }

    @HostListener('window: scroll')
    onScroll() {
        this.playAnimateProgress();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.isShowPlans = event.target.innerWidth < 768 ? false : true;
    }

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        public dialog: MatDialog,
        private localeService: LocaleService,
        private readonly _meta: MetaService,
        private _el: ElementRef,
    ) {
        super(_el);
        this.hosting = this.localeService.getContent().hosting.filter(category => category.show === true);
        this.info.price = this.hosting[1].plans[0].periods[0].price;
        this.links = this.localeService.getContent().links;
        this.info.symbol = this.localeService.symbol;
        this.symbol = this.localeService.symbol;
        this.locale = this.localeService.locale;
        this.productivity = this.localeService.getProductivityContent();
    }

    

    ngOnInit() {
        //Set Meta Tags
        if(this.locale == "es-CO")
            this._setMetaCO();
        else if (this.locale == "es-MX")
            this._setMetaMX();
        else if (this.locale == "es-PE")
            this._setMetaPE();
        else if (this.locale == "es-AR")
            this._setMetaAR();
        else
            this._setMetaCL();

        if (isPlatformBrowser(this.platformId)) {
            this.addVideoControlsForMobyle();
            this.removePlansMobyle();

            if (window.innerWidth >= 768) {
                this.isActiveChartsForDesktop = true;
                this.isActiveProgressForMobyle = false;
            } else {
                this.isActiveChartsForDesktop = false;
                this.isActiveProgressForMobyle = true;
            }
        }

        this.setMaxPerc();
    }

    setMaxPerc() {
        if (this.productivity.chart2){
            this.maxPerc2 = this.productivity.chart2.endValue;
            if (this.productivity.chart2.maxValue)
                this.maxPerc2 = this.maxPerc2 * 100 / this.productivity.chart2.maxValue;
        }
        if (this.productivity.chart3){
            this.maxPerc3 = this.productivity.chart3.endValue;
            if (this.productivity.chart3.maxValue)
                this.maxPerc3 = this.maxPerc3 * 100 / this.productivity.chart3.maxValue;
        }
        if (this.productivity.chart4){
            this.maxPerc4 = this.productivity.chart4.endValue;
            if (this.productivity.chart4.maxValue)
                this.maxPerc4 = this.maxPerc4 * 100 / this.productivity.chart4.maxValue;
        }
        if (this.productivity.chart5){
            this.maxPerc5 = this.productivity.chart5.endValue;
            if (this.productivity.chart5.maxValue)
                this.maxPerc5 = this.maxPerc5 * 100 / this.productivity.chart5.maxValue;
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    removePlansMobyle() {
        if (window.innerWidth < 768) {
            this.isShowPlans = false;
            return this.isShowPlans;
        }
    }

    playAnimateProgress() {
        if (window.innerWidth <= 767) {
            const sectionProgress = this._document.getElementsByClassName('progressProductivity')[0] as HTMLElement;

            if (this.isInViewport(sectionProgress) && !this.chartsIsAnimated) {
                setTimeout(() => {
                    this.animateProgress1();
                    this.animateProgress2();
                    this.animateProgress3();
                    this.animateProgress4();
                    this.animateProgress5();
                }, 100);
                this.chartsIsAnimated = true;
            }
        }
    }
    // progress Productivity for mobyle
    animateProgress1() {
        const progress = this._document.getElementsByClassName('progress')[0] as HTMLElement;
        progress.style.width = this.curPerc + '%';
        this.curPerc++;

        if (this.curPerc < this.maxPerc1 + 1) {
            requestAnimationFrame(() => {
                this.animateProgress1();
            });
        }
    }

    animateProgress2() {
        if (this.productivity.chart2){
            const progress = this._document.getElementsByClassName('progress')[1] as HTMLElement;
            progress.style.width = this.curPerc + '%';
            this.perc2 = '-' + (100 - this.curPerc) + '%';
            this.curPerc++;

            if (this.curPerc < this.maxPerc2 + 1) {
                requestAnimationFrame(() => {
                    this.animateProgress2();
                });
            }
        }
    }

    animateProgress3() {
        if (this.productivity.chart3){
            const progress = this._document.getElementsByClassName('progress')[2] as HTMLElement;
            progress.style.width = this.curPerc + '%';
            this.perc3 = '-' + (100 - this.curPerc) + '%';
            this.curPerc++;

            if (this.curPerc < this.maxPerc3 + 1) {
                requestAnimationFrame(() => {
                    this.animateProgress3();
                });
            }
        }
    }

    animateProgress4() {
        if (this.productivity.chart4){
            const progress = this._document.getElementsByClassName('progress')[3] as HTMLElement;
            progress.style.width = this.curPerc + '%';
            this.perc4 = '-' + (100 - this.curPerc) + '%';
            this.curPerc++;

            if (this.curPerc < this.maxPerc4 + 1) {
                requestAnimationFrame(() => {
                    this.animateProgress4();
                });
            }
        }
    }

    animateProgress5() {
        if (this.productivity.chart5){
            const progress = this._document.getElementsByClassName('progress')[4] as HTMLElement;
            progress.style.width = this.curPerc + '%';
            this.perc5 = '-' + (100 - this.curPerc) + '%';
            this.curPerc++;

            if (this.curPerc < this.maxPerc5 + 1) {
                requestAnimationFrame(() => {
                    this.animateProgress5();
                });
            }
        }
    }

    isInViewport(elem) {
        if (!elem) return false;
        const viewportWindow = window.innerHeight || this._document.documentElement.clientHeight;
        const elemRect = elem.getBoundingClientRect();
        return (elemRect.top >= 104 && elemRect.bottom <= viewportWindow);
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    addVideoControlsForMobyle() {
        setTimeout(_ => {
            const video1 = this._document.getElementsByClassName('video1')[0] as HTMLVideoElement;
            const video2 = this._document.getElementsByClassName('video2')[0] as HTMLVideoElement;
            if (window.innerWidth < 670) {
                if (video1) video1.setAttribute('controls', '');
                if (video2) video2.setAttribute('controls', '');
            }
        }, 1500); //Lucky number
    }

    toggleVideoCompany(el) {
        const element = this._findAncestorFromEl(el.target, 'videoCompany__video');
        const playEl = element.classList.contains('play');
        const video = element.getElementsByClassName('video')[0] as HTMLVideoElement;

        if (window.innerWidth > 670) {
            if (playEl) {
                element.classList.remove('play');
                video.pause();
            } else {
                element.classList.add('play');
                video.play();
            }
        }
    }

    openDialogSyteHtml() {
        const dialogRef = this.dialog.open(DialogSyteHtmlComponent, {
            width: '100%',
            panelClass: 'dialogFullWidth',
            backdropClass: 's-dialog-transp',
        });

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    openDialogSyteECommerce() {
        const dialogRef = this.dialog.open(DialogSyteEcommerceComponent, {
            width: '100%',
            panelClass: 'dialogFullWidth',
            backdropClass: 's-dialog-transp',
        });

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    openDialogSyteBlog() {
        const dialogRef = this.dialog.open(DialogSyteBlogComponent, {
            width: '100%',
            panelClass: 'dialogFullWidth',
            backdropClass: 's-dialog-transp',
        });

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    openDialogSyteApplication() {
        const dialogRef = this.dialog.open(DialogSyteApplicationComponent, {
            width: '100%',
            panelClass: 'dialogFullWidth',
            backdropClass: 's-dialog-transp',
        });

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    navigateToSection(className) {
        this.scrollToSection(className);
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    }

    private _setMetaCO(){
        this._meta.setTitle('HOSTING | Web Hosting N°1 de Colombia 100% SSD y 99.9% Uptime');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'BlueHosting es la Empresa Web Hosting Lider N°1 en Colombia con el Hosting Más Rápido y Estable en el Mercado con Datacenter Propio, 100% Discos Sólidos SSD.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, ssd web hosting, hosting joomla, hosting foro, hosting wordpress, hosting phpbb, hosting discos solidos, web hosting, registrar dominio, servidor privado virtual,vps,reseller web hosting, reseller hosting, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Colombia');
        this._meta.setTag('og:locale', 'es_CO');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
    }

    private _setMetaCL(){
        this._meta.setTitle('HOSTING | Web Hosting N°1 de Chile 100% SSD y 99.9% Uptime');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'BlueHosting es la Empresa Web Hosting Lider N°1 en Chile con el Hosting Más Rápido y Estable en el Mercado con Datacenter Propio, 100% Discos Sólidos SSD.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, ssd web hosting, hosting joomla, hosting foro, hosting wordpress, hosting phpbb, hosting discos solidos, web hosting, registrar dominio, servidor privado virtual,vps,reseller web hosting, reseller hosting, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Chile');
        this._meta.setTag('og:locale', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    private _setMetaPE(){
        this._meta.setTitle('HOSTING | Web Hosting N°1 del Perú 100% SSD y 99.9% Uptime');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'BlueHosting es la Empresa Web Hosting Lider N°1 en el Perú con el Hosting Más Rápido y Estable en el Mercado con Datacenter Propio, 100% Discos Sólidos SSD.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, ssd web hosting, hosting joomla, hosting foro, hosting wordpress, hosting phpbb, hosting discos solidos, web hosting, registrar dominio, servidor privado virtual,vps,reseller web hosting, reseller hosting, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Perú');
        this._meta.setTag('og:locale', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
    private _setMetaMX(){
        this._meta.setTitle('HOSTING | Web Hosting N°1 de México 100% SSD y 99.9% Uptime');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'BlueHosting es la Empresa Web Hosting Lider N°1 en México con el Hosting Más Rápido y Estable en el Mercado con Datacenter Propio, 100% Discos Sólidos SSD.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, ssd web hosting, hosting joomla, hosting foro, hosting wordpress, hosting phpbb, hosting discos solidos, web hosting, registrar dominio, servidor privado virtual,vps,reseller web hosting, reseller hosting, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/home.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en México');
        this._meta.setTag('og:locale', 'es_MX');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_PE');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }

    private _setMetaAR(){
        this._meta.setTitle('HOSTING | Web Hosting N°1 de Argentina 100% SSD y 99.9% Uptime');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('description', 'BlueHosting es la Empresa Web Hosting Lider N°1 en Argentina con el Hosting Más Rápido y Estable en el Mercado con Datacenter Propio, 100% Discos Sólidos SSD.');
        // tslint:disable-next-line:max-line-length
        this._meta.setTag('keywords', 'hosting, web hosting, ssd web hosting, hosting joomla, hosting foro, hosting wordpress, hosting phpbb, hosting discos solidos, web hosting, registrar dominio, servidor privado virtual,vps,reseller web hosting, reseller hosting, alojamiento web');
        this._meta.setTag('author', 'Bluehosting');
        this._meta.setTag('og:type', 'website');
        this._meta.setTag('og:image:type', 'image/png');
        this._meta.setTag('og:image', this.URL.BLUEHOSTING_WEBSITE + 'assets/images/og/OG_home_ARG.png');
        this._meta.setTag('og:image:width', '644');
        this._meta.setTag('og:image:height', '373');
        this._meta.setTag('og:image:alt', 'Web Hosting #1 en Argentina');
        this._meta.setTag('og:locale', 'es_AR');
        this._meta.setTag('og:locale:alternate', 'es_CL');
        this._meta.setTag('og:locale:alternate', 'es_CO');
    }
}
