import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { LocaleService } from '../../services/locale.service';


@Component({
    selector: 'app-tab-plans',
    templateUrl: './tab-plans.component.html'
})
export class TabPlansComponent implements OnInit {
    activeCategory = 1;
    activeCategoryName = '';
    categoryToShow;

    @Input() hosting;
    symbol = '';

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private localeService: LocaleService
    ) {
        this.symbol = this.localeService.symbol;
    }

    ngOnInit() {
        this.categoryToShow = this.hosting.filter(category => category.id === this.activeCategory)[0];
        this.activeCategoryName = this.categoryToShow.category;
    }

    openPlan(categoryID: string) {
        this.activeCategory = parseInt(categoryID, 10);
        this.categoryToShow = this.hosting.filter(category => category.id === this.activeCategory)[0];
        this.activeCategoryName = this.categoryToShow.category;
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }

    addClassActive(el) {
        const nav = this._document.getElementsByClassName('tabPlans__nav')[0] as HTMLElement;
        const button = this._findAncestorFromEl(el.target, 'tabPlans__nav_btn');

        if (!!button) {
            const btnList = nav.getElementsByClassName('tabPlans__nav_btn') as unknown as NodeListOf<HTMLElement>;
            const isActiveBtn = button.classList.contains('active');

            if (isActiveBtn) {
                return;
            } else {
                [].forEach.call(btnList, (el) => {
                    el.classList.remove('active');
                });
                button.classList.add('active');

            }
        }
    }

    private _findAncestorFromEl(el, cls) {
        if (el.classList.contains(cls)) {
            return el;
        }
        return this._findAncestor(el, cls);
    }

    private _findAncestor(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls)) {}
        return el;
    }

}
