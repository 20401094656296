<!-- START menuPlans-->
<div class="menuPlans">
  <!-- start menuPlans__top -->
  <div class="menuPlans__top">
    <div class="menuPlans__ttl" i18n>Comparar planes</div>

    <div class="menuPlans__menu">
      <!-- <button class="btn btnBlue1" mat-button (click)="openTab($event)" i18n>PERSONAS -->
      <button class="btn btnBlue1" mat-button (click)="toggleOnePlan('1'); openTablePlanes($event)" i18n>EMPRENDEDORES
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button class="btn btnBlue2" mat-button (click)="toggleOnePlan('2'); openTablePlanes($event)" i18n>PYMES
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button class="btn btnBlue3" mat-button (click)="toggleOnePlan('3'); openTablePlanes($event)" i18n>EMPRESAS
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <button class="btn btnBlue4" mat-button (click)="toggleOnePlan(null); openTablePlanes($event)" i18n>VER TODOS
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
    </div>

  </div>
  <!-- end menuPlans__top -->
  <!-- start menuPlans__body -->
  <div class="menuPlans__body">

    <div class="overflow-auto">
      <div class="tablePlans" [class.w-auto]="isShowClassWIdth" [class.table_col-4]="isShowTableCol4" [ngClass]="addClassTable()" *ngIf="isActiveTablePlanes">
        <!-- START table header -->
        <div class="tablePlans__header">
          <div class="tablePlans__ttl tablePlans__col-1">
            {{ getTitle() }}
          </div>

          <div class="tablePlans__f" *ngFor="let category of categoriesToShow">
            <div class="tablePlans__inner" *ngIf="isAllShowed; else showOne">
              <div class="tablePlans__inner w100">
                <span class="tablePlans__th w50 tablePlans__col-2" *ngFor="let plan of category.plans">
                  {{plan.name}}
                  <span class="tablePlans__th_sub">{{category.category}}</span>
                </span>
              </div>
            </div>

            <ng-template #showOne>
              <div class="tablePlans__inner">
                <div class="tablePlans__th tablePlans__col-2" *ngFor="let plan of category.plans">
                  {{ plan.name }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- END table header -->
        <!-- START table body -->
        <div class="tablePlans__body" [ngClass]="isAllShowed ? 'body_font_smaller' : ''">
          <span class="tablePlans__row tablePlans__f" *ngFor="let prop of planProps; let i = index">
            <span class="tablePlans__td-caption tablePlans__col-1">{{ prop }}</span>
            <ng-template ngFor let-category [ngForOf]="categoriesToShow">
              <ng-template ngFor let-plan [ngForOf]="category.plans">
                <span class="tablePlans__td tablePlans__col-2" *ngIf="plan.description[i].type === 'icon' " [ngClass]="plan.description[i].info"></span>
                <span class="tablePlans__td center tablePlans__col-2" *ngIf="plan.description[i].type === 'text' && plan.description[i].isShowCell"
                  [ngClass]="plan.description[i].isFullWidth ? 'tablePlans__td__full-width'+plan.description[i].fullWidthNumber : ''">{{ plan.description[i].info }}</span>
              </ng-template>
            </ng-template>
          </span>
        </div>
        <!-- END table body -->
        <!-- START table footer -->

        <div class="tablePlans__footer" [ngClass]="isAllShowed ? 'text_smaller' : ''">
          <div class="tablePlans__col-1"></div>
          <div class="tablePlans__row tablePlans__f" *ngFor="let category of categoriesToShow">
            <div class="tablePlans__row tablePlans__f">
              <span class="tablePlans__td tablePlans__col-2" *ngFor="let plan of category.plans">

                <!-- <p></p> -->
                <div class="price price_total">
                  <span class="price_actual">
                    <span class="currency">{{ symbol }}</span>{{ plan.periods[0].price | currencyLocale }}
                  </span>
                </div>
                <p class="price_sub" i18n>Anual + IVA</p>
                <button id="menuplans-{{category.category}}-contratar-{{plan.name}}" mat-button class="btn btnRedTransp" (click)="goToUrl( plan.url )" i18n>contratar</button>
              </span>
            </div>
          </div>
        </div>
        <!-- END table footer -->
      </div>
    </div>

    <!-- START palnsInclude -->
    <div class="palnsInclude" *ngIf="isActiveTablePlanes">
      <h2 class="ttl2">Todos los planes incluyen</h2>
      <div class="palnsInclude__item" *ngFor="let plan of plansInclude">
        <div class="palnsInclude__ttl">{{plan.ttl}}</div>
        <div class="palnsInclude__info">{{plan.info}}</div>
      </div>
    </div>
    <!-- END palnsInclude -->

  </div>
  <!-- end menuPlans__body -->
</div>
<!-- END menuPlans-->