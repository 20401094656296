import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { CONTACTS } from '../../../../../common/contacts.constants';
import { LocaleService } from '../../services/locale.service';
import { Contact } from '../../../../../common/Contact';
import { URL } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
    isShowItemMenuFooter = false;

    contactList: Contact[] = CONTACTS;
    contact: Contact;
    URL = URL;

    constructor(private localeService: LocaleService,
        @Inject(PLATFORM_ID) private platformId: any
    ) { }

    ngOnInit() {
        this.localeService.getMessage().subscribe(c => {
            this.contact = c;
        });

        if (window.innerWidth < 992) {
            this.isShowItemMenuFooter = true;
        }
    }

    scrollTop() {
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    goToUrl(url: string): void {
        window.open(url, '_blank');
    }
}
