<!-- START Header -->
<header [ngClass]="promotionStrip ? 'wrapHeader_strip':'wrapHeader'">
  <div class="container">
    <div class="wrapHeader__top">
      <ul class="headerMenu">
        <li>
          <a id="bh-d-header-emprendedores" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop()">EMPRENDEDORES</a>
        </li>
        <li>
          <a id="bh-d-header-negocios" [routerLink]="['/web-hosting-negocio/.']" [routerLinkActive]="['active']" (click)="scrollTop()">NEGOCIOS</a>
        </li>
        <li>
          <a id="bh-d-header-empresas" [routerLink]="['/web-hosting-empresa/.']" [routerLinkActive]="['active']" (click)="scrollTop()">EMPRESAS</a>
        </li>
      </ul>

      <ul class="headerMenu">
        <li>
          <a id="bh-d-header-pago" [routerLink]="['/pago-directo/.']" [routerLinkActive]="['active']">
            <svg-icon-sprite class="tarjeta" [src]="'icon-sprite-credit-card'" [width]="'16px'">
            </svg-icon-sprite>
            <span>Pago de cuentas</span>
          </a>
        </li>
        <li>
          <a role='button' id="bh-d-header-soporte" #triggerDialog (click)="openDialogCustomMenu()">Soporte <mat-icon>keyboard_arrow_down</mat-icon></a>
        </li>
        <li class="loged_in">
          <a role="button" id="bh-d-header-iniciarsesion" (click)="goToUrl( URL.BLUEHOSTING_LOGIN )">Iniciar sesión</a>
        </li>
      </ul>

      <button mat-button [id]="isTablet ? idButtonContact : ''" class="btn btnTransp" [routerLink]="['/contactar-con-ventas/.']" [routerLinkActive]="['active']" (click)="scrollTop()">
        Contactar con ventas
      </button>
    </div>

    <div class="wrapHeader__bottom">
      <a [routerLink]="['/']" (click)="scrollTop()" class="wrap_logo">
        <img  class="logo_white" 
              src="/assets/images/bluehosting.svg" 
              width="300px"
              height="54px"
              alt="bluehosting">
        <img  class="logo_blue" 
              src="/assets/images/bluehosting-blue.svg" 
              width="300px"
              height="54px"
              alt="bluehosting">
        <span>By Haulmer</span>
      </a>

      <!-- START headerMenu -->
      <ul class="headerMenu">
        <li class="dropdown" (mouseenter)="menuDropdownFadeIn($event)" (mouseleave)="closeMenuDropdown($event)">
          <a id="bh-d-header-webhosting" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="scrollTop()">
            Web Hosting
          </a>
          <!-- start dropdown menu -->
          <ul class="headerMenu__dropdown" (mouseleave)="closeMenuDropdown($event)">
            <li>
              <button id="bh-d-header-webhosting-emprendedores" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon person"></i>
                <span class="inner">
                  <span class="ttl">Emprendedores</span>
                  <span class="sub_ttl">Servicios pensados para ayudarte a alcanzar tus metas</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-d-header-webhosting-negocios" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting-negocio/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon pyme"></i>
                <span class="inner">
                  <span class="ttl">Negocios</span>
                  <span class="sub_ttl">Planes creados para impulsar tu desarrollo</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-d-header-webhosting-wordpress" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/hosting-negocio-wordpress/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon wordpress"></i>
                <span class="inner">
                  <span class="ttl">Wordpress</span>
                  <span class="sub_ttl">Hosting potenciado para obtener lo mejor de Wordpress</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-d-header-webhosting-empresas" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/web-hosting-empresa/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon empresa"></i>
                <span class="inner">
                  <span class="ttl">Empresas</span>
                  <span class="sub_ttl">Recursos dispuestos para potenciar tus inversiones</span>
                </span>
              </button>
            </li>
          </ul>
          <!-- end dropdown menu -->
        </li>

        <li>
          <a id="bh-d-header-dominios" [routerLink]="['/dominios/.']" [routerLinkActive]="['active']" (click)="scrollTop()">Dominios</a>
        </li>
        <li class="dropdown" (mouseenter)="menuDropdownFadeIn($event)" (mouseleave)="closeMenuDropdown($event)">
          <a id="bh-d-header-vps" [routerLink]="['/vps/.']" [routerLinkActive]="['active']" (click)="scrollTop()">Cloud Server</a>
          <ul class="headerMenu__dropdown" (mouseleave)="closeMenuDropdown($event)">
            <li>
              <button id="bh-d-header-webhosting-vps" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/vps/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon person"></i>
                <span class="inner">
                  <span class="ttl">VPS SSD</span>
                </span>
              </button>
            </li>
            <li>
              <button id="bh-d-header-webhosting-vps" class="headerMenu__dropdown-btn" mat-button [routerLink]="['/vps-con-backup/.']" (click)="closeMenuDropdown($event);scrollTop()">
                <i class="icon pyme"></i>
                <span class="inner">
                  <span class="ttl">VPS SSD + Backup</span>
                </span>
              </button>
            </li>
          </ul>
        </li>
        <li class="dropdown">
          <a id="bh-d-header-seguridad" [routerLink]="['/certificados-ssl/.']" [routerLinkActive]="['active']" (click)="scrollTop()">
            Certificado SSL
          </a>
        </li>
        <li>
          <a id="bh-d-header-reseller" [routerLink]="['/hosting-reseller/.']" [routerLinkActive]="['active']" (click)="scrollTop()">Programa Reseller</a>
        </li>

        <li class="dropdownBackground">
          <span class="dropdownBackground__inner noTransition"></span>
        </li>
      </ul>
      <!-- END headerMenu -->

      <button mat-button class="btn btnTransp" [id]="isDesktop ? idButtonContact : ''" [routerLinkActive]="['active']" (click)="scrollTop()" onclick="return gtag_report_conversion('/contactar-con-ventas/')">
        Contactar con ventas
      </button>
    </div>
  </div>

  <button id="bh-m-hamburger" mat-icon-button aria-label="Mostrar Opciones" class="humburger" (click)="onClickOpenMenu()">
    <span class="humburger_inner"></span>
  </button>

</header>
<!-- START mobyleMenu -->
<nav class="mobyleMenu">
  <a [routerLink]="['/web-hosting/.']" class="wrap_logo__grey wrap_logo__img" *ngIf="isActiveMobyleMenu1" (click)="onClickCloseMenu();scrollTop()">
    <img  class="logo_desk" 
          src="/assets/images/bluehosting-blue.svg" 
          width="300px"
          height="54px"
          alt="bluehosting">
  </a>

  <!-- <div class="mobyleMenu__ttl" *ngIf="isActiveMobyleMenu2">
    <button mat-icon-button
      (click)="closeDropdownMenu('seguridad')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Seguridad Web
  </div> -->

  <div class="mobyleMenu__ttl" *ngIf="isActiveMobyleMenu3">
    <button mat-icon-button (click)="closeDropdownMenu('web hosting')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Web Hosting
  </div>
  <div class="mobyleMenu__ttl" *ngIf="isActiveMenuVPS">
    <button mat-icon-button (click)="closeDropdownMenu('vps')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Cloud Server
  </div>

  <div class="mobyleMenu__ttl" *ngIf="isActiveDropdownSupport">
    <button mat-icon-button (click)="closeDropdownMenu('soporte')">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    Soporte
  </div>

  <button mat-icon-button aria-label="Ocultar Opciones" class="humburger" (click)="onClickCloseMenu()">
    <span class="humburger_inner"></span>
  </button>

  <div class="mobyleMenu__btn">
    <button [id]="isMobile ? 'bh-contactsales' : ''" mat-button class="btnRed" [routerLink]="['/contactar-con-ventas/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">
      Contactar con ventas
    </button>
  </div>

  <div class="mobyleMenu__wrapper">
    <ul class="mobyleMenu__list" *ngIf="isActiveMobyleMenu1">
      <li>
        <a role="button" id="bh-m-hamburger-webhosting" [routerLinkActive]="['active']" (click)="scrollTop(); openDropdownMenu('web hosting')">
          <span>Web Hosting</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="bh-m-hamburger-dominios" [routerLink]="['/dominios/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Dominios</a>
      </li>
      <li>
        <a role="button" id="bh-m-hamburger-vps" [routerLinkActive]="['active']" (click)="scrollTop(); openDropdownMenu('vps')">
          <span>Cloud Server</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li>
        <a id="bh-m-hamburger-seguridad" [routerLinkActive]="['active']" (click)="scrollTop(); onClickCloseMenu()" [routerLink]="['/certificados-ssl/.']">
          <span>Certificado SSL</span>
        </a>
      </li>
      <li>
        <a id="bh-m-hamburger-reseller" [routerLink]="['/hosting-reseller/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Programa Reseller</a>
      </li>
    </ul>

    <ul class="mobyleMenu__list" *ngIf="isActiveMobyleMenu3">
      <li>
        <a id="bh-m-hamburger-webhosting-emprendedores" [routerLink]="['/web-hosting/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Emprendedores</a>
      </li>
      <li>
        <a id="bh-m-hamburger-webhosting-negocios" [routerLink]="['/web-hosting-negocio/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Negocios</a>
      </li>
      <li>
        <a id="bh-m-hamburger-webhosting-wordpress" [routerLink]="['/hosting-negocio-wordpress/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Wordpress</a>
      </li>
      <li>
        <a id="bh-m-hamburger-webhosting-empresas" [routerLink]="['/web-hosting-empresa/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Empresas</a>
      </li>
    </ul>

    <ul class="mobyleMenu__list" *ngIf="isActiveMenuVPS">
      <li>
        <a id="bh-m-hamburger-webhosting-vps" [routerLink]="['/vps/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">VPS SSD</a>
      </li>
      <li>
        <a id="bh-m-hamburger-webhosting-vps" [routerLink]="['/vps-con-backup/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">VPS SSD + Backup</a>
      </li>
    </ul>

    <div class="dropdownWrapper" *ngIf="isActiveDropdownSupport">
      <ul class="customDropdownMenu">
        <li class="customDropdownMenu__item">
          <a id="bh-support" (click)="goToUrl('contactar-con-soporte')" target="_blank">
            <i class="icon icon_support"></i>
            <span class="title">Contactar Soporte</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a id="bh-support-doc" href="https://help.haulmer.com/hc/web-hosting" target="_blank">
            <i class="icon icon_doc"></i>
            <span class="title">Documentación</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a id="bh-m-hamburger-soporte-status" href="https://status.bluehosting.host/" target="_blank">
            <i class="icon icon_status_service"></i>
            <span class="title">Status de Servicios</span>
          </a>
        </li>
        <li class="customDropdownMenu__item">
          <a href="https://survey.typeform.com/to/zQ7I32J9#empresa=Bluehosting&issuetype=11208" target="_blank">
            <i class="icon icon_suggestions"></i>
            <span class="title">Sugerencias o reclamos</span>
          </a>
        </li>
      </ul>
    </div>

    <ul class="mobyleMenu__list bg_grey" *ngIf="!isActiveDropdownSupport">
      <li>
        <a id="bh-m-header-pago" [routerLink]="['/pago-directo/.']" [routerLinkActive]="['active']" (click)="onClickCloseMenu()">
          <span>Pago de cuentas</span>
        </a>
      </li>
      <li>
        <a role="button" id="bh-m-hamburger-soporte" (click)="openDropdownMenu('soporte')">
          <span>Soporte</span>
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </li>
      <li class="loged_in">
        <a role="button" id="bh-m-hamburger-iniciarsesion" (click)="goToUrl( URL.BLUEHOSTING_LOGIN )" [routerLinkActive]="['active']" (click)="onClickCloseMenu();scrollTop()">Iniciar sesión</a>
      </li>
    </ul>
  </div>
</nav>
<!-- END mobyleMenu -->
<!-- END Header -->

<div class="overlay" (click)="onClickCloseMenu()"></div>