<ul class="customDropdownMenu">
  <li>
    <button id="bh-support" class="customDropdownMenu__item" mat-button (click)="goToUrl('contactar-con-soporte')" tabindex="-1">
      <i class="icon icon_support"></i>Contactar Soporte
    </button>
  </li>
  <li>
    <button id="bh-support-doc" class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://help.haulmer.com/hc/web-hosting')">
      <i class="icon icon_doc"></i> Documentación
    </button>
  </li>
  <li>
    <button class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://status.bluehosting.host/')">
      <i class="icon icon_status_service"></i>Status de Servicios
    </button>
  </li>
  <li>
    <button class="customDropdownMenu__item" mat-button tabindex="-1" (click)="goToUrl('https://survey.typeform.com/to/zQ7I32J9#empresa=Bluehosting&issuetype=11208')">
      <i class="icon icon_suggestions"></i>Sugerencias o reclamos
    </button>
  </li>
</ul>
