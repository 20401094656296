import { IModalData } from './../../../common/interfaces/promotions.interface';
import { Component, Inject, PLATFORM_ID, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { LocaleService } from './services/locale.service';
import { DialogPromoWrapperComponent } from './promotions/dialog-promo/dialog-promo.component';
import { MatDialog } from '@angular/material/dialog';
import { ScrollToClass } from '../../../common/scrollTo.class';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent extends ScrollToClass implements OnInit {
    title = 'Bluehosting';
    transportUrl = '';
    //TODO: Cinta promocional
    public promotionStrip: boolean = false;
    //TODO: Modal promocional
    private dialogPromo = {
        available: false,
        timeOut: 5000
    }
    constructor(
        private _router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private serviceLocale: LocaleService,
        private dialog: MatDialog,
        private _el: ElementRef
    ) {
        super(_el);
    }

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.loadScript();
            }, 2000);
            setTimeout(() => {
                this.initChatScript();
            }, 4000);
            if (this.dialogPromo.available) {
                setTimeout(() => {
                    this.openDialogPromo();
                }, this.dialogPromo.timeOut)
            }
        }
        if (this.serviceLocale.TLD === 'cl') {
            this.loadHotjar();
            this.transportUrl = ',{transport_url : "https://analytics.bluehosting.cl"}';
        }
        const gtm = this.serviceLocale.getGTM();
        const gtag = this.serviceLocale.getGtag();
        this.loadGTM(gtm, gtag);
    }

    loadGTM(GTMlolcale: string, idGtag: string): void {
        // insert into <head></head> script for Google Tag Manager
        const scriptGTM = document.createElement('script');
        scriptGTM.type = 'text/javascript';
        scriptGTM.innerHTML = 'setTimeout(function(){(function(w,d,s,l,i){w[l]=w[l]||' +
            '[];w[l].push({"gtm.start":new Date().getTime(),event:"gtm.js"});' +
            'var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!="dataLayer"?"&l="+l:"";' +
            'j.async=true;j.src="https://www.googletagmanager.com/gtm.js?id="+i+dl;' +
            'f.parentNode.insertBefore(j,f);})(window,document,"script","dataLayer","' + GTMlolcale + '");},3000);';
        document.getElementsByTagName('head')[0].appendChild(scriptGTM);
        // insert into <body></body> noscript for Google Tag Manager
        const noscriptGTM = document.createElement('noscript');
        noscriptGTM.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + GTMlolcale +
            '"height="0" width="0" style="display:none;visibility:hidden"></iframe>';
        document.getElementsByTagName('body')[0].appendChild(noscriptGTM);
        // insert into <head></head> script source Google Analytics
        const scriptSRC = document.createElement('script');
        scriptSRC.src = 'https://www.googletagmanager.com/gtag/js?id=' + idGtag;
        scriptSRC.async = true;
        document.getElementsByTagName('head')[0].appendChild(scriptSRC);
        // insert into <head><head> script gtag
        const scriptGtag = document.createElement('script');
        scriptGtag.innerHTML = 'window.dataLayer = window.dataLayer ||' +
            '[];function gtag(){dataLayer.push(arguments);' +
            '}gtag("js", new Date());gtag("config","' + idGtag + '"' + this.transportUrl + ');';
        document.getElementsByTagName('head')[0].appendChild(scriptGtag);
    }


    loadHotjar(): void {
        const scriptHotjar = document.createElement('script');
        scriptHotjar.type = 'text/javascript';
        scriptHotjar.innerHTML = 'setTimeout(function(){(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:634102,hjsv:6};a=o.getElementsByTagName("head")[0];r=o.createElement("script");r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");},1500);';
        document.getElementsByTagName('head')[0].appendChild(scriptHotjar);
    }

    loadScript(): void {
        const node1 = document.createElement('script');
        node1.src = 'https://cdn.slaask.com/chat.js';
        node1.type = 'text/javascript';
        node1.async = true;
        document.getElementsByTagName('head')[0].appendChild(node1);
    }

    initChatScript() {
        const countryList = ["mx", "ar", "ce", "net"];
        if (countryList.includes(this.serviceLocale.TLD)) {
            const scriptChat = document.createElement('script');
            scriptChat.type = 'text/javascript';
            scriptChat.innerHTML = `
            var MessageBirdChatWidgetSettings = {
                widgetId: '260ee3e3-0e6f-4da2-8756-2459c391f05f', initializeOnLoad: true,
                };
                !function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))console.error("MessageBirdChatWidget: Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),t.async=!0,t.id="live-chat-widget-script";
                var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};"complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}}();
            `;
            document.getElementsByTagName('head')[0].appendChild(scriptChat);
        }
    }

    isErrorPage(): boolean {
        const re = new RegExp('^/success');
        const re_gt = new RegExp('/?gclid=.*');
        if (this._router.url === '/' ||
            this._router.url === '/web-hosting/.' ||
            this._router.url === '/web-hosting-negocio/.' ||
            this._router.url === '/hosting-negocio-wordpress/.' ||
            this._router.url === '/web-hosting-empresa/.' ||
            this._router.url === '/certificados-ssl/.' ||
            this._router.url === '/dominios/.' ||
            this._router.url === '/hosting-reseller/.' ||
            this._router.url === '/formas-de-pago/.' ||
            this._router.url === '/vps/.' ||
            this._router.url === '/vps-con-backup/.' ||
            this._router.url === '/contactar-con-ventas/.' ||
            this._router.url === '/contactar-con-soporte/.' ||
            this._router.url === '/promociones/cyberday/.' ||
            this._router.url === '/promociones/cybermonday/.' ||
            this._router.url === '/promociones/blackfriday/.' ||
            this._router.url === '/promociones/hotsale/.' ||
            this._router.url === '/promociones/web-hosting/.' ||
            this._router.url === '/promociones/fiestas-patrias/.' ||
            this._router.url === '/promociones/felices-fiestas/.' ||
            this._router.url === '/promociones/web-hosting/.' ||
            this._router.url === '/promociones/cyberdays/.' ||
            this._router.url === '/promociones/halloween/.' ||
            this._router.url.startsWith('/pago-directo/') ||
            re_gt.test(this._router.url) ||
            re.test(this._router.url)) {
            return false;
        }
        return true;
    }

    isContactPage(): boolean {
        if (this._router.url === '/contactar-con-ventas/.' || this._router.url === '/contactar-con-soporte') {
            return false;
        }
        return true;
    }

    public openDialogPromo() {
        const dialogRef = this.dialog.open(DialogPromoWrapperComponent, {
            hasBackdrop: true,
            disableClose: true,
            id: 'promocionalModal',
            maxWidth: '',
            width: '632px',
            data: { status: false, prCode: '' }
        });

        dialogRef.afterClosed().subscribe((res: IModalData) => {
            if (res.status) {
                sessionStorage.setItem('prCode', res.prCode);
                this._router.navigate(['/web-hosting/.']);
                this.scrollToSection('pad');
            }
        });
    }
}
