<!-- START firstScreen -->
<div [ngClass]="{'dominio': true}">
<section class="firstScreen" [class.h-auto]="!isShowResultDomainName">
  <div class="container">
    <div class="firstScreen__inner center">
      <div class="firstScreen__sup_ttl" i18n *ngIf="isShowResultDomainName">FÁCIL Y RÁPIDO</div>
      <h1 class="firstScreen__ttl" i18n *ngIf="isShowResultDomainName">OBTÉN TU DOMINIO AL INSTANTE</h1>

      <div class="ttl2 mb" *ngIf="!isShowResultDomainName">Buscador de Dominios</div>

      <form class="formSearchSites" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="formSearchSites__wrap">
          <label>www.</label>
          <input type="search" formControlName="search" placeholder="ingrese su sitio aquí" maxlength="63" minlength="6">
          <button class="btn btnRed" mat-button i18n [disabled]="(!form.valid || !activeCaptcha || isLoading)">
            <span [class.hidden]="isLoading">BUSCAR</span>
            <span [class.hidden]="!isLoading">
              <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
            </span>
          </button>
        </div>
        <div class="captcha" *ngIf="!isShowResultDomainName && isShowResultDomain">
        </div>
      </form>
    </div>
  </div>

  <!-- start domains -->
  <div class="wrap_varDomains" *ngIf="isShowResultDomainName && !isShowResultDomain">
    <div class="container">

      <div class="varDomains__item" *ngFor="let domain of domains">
        <i class="icon_domain" [ngStyle]="{'backgroundImage': 'url('+ domain.icon_url +')'}"></i>
        <span>{{ domain.domain_name }}</span>
      </div>

      <div class="varDomains__item-inner flex">
        <div class="varDomains__price">
          <span class="price_sup" i18n>desde</span>
          <span class="price_value">
            <span class="price_current">{{ symbol }}</span>{{domainCards[tldNumber].price | currencyLocale}}
          </span>
          <span class="price_sub" i18n>Anual + IVA</span>
        </div>

        <button class="see_price" (click)="scrollToCards()" mat-button i18n>Ver todos los precios</button>
      </div>

    </div>
  </div>
  <!-- end domains -->
</section>
</div>
<!-- END firstScreen -->

<!-- START domainInfo and domainRegistered-->
<div class="wrapDomain pad" *ngIf="!isShowResultDomainName && !isShowResultDomain">
  <div class="container">
    <h2 class="ttl2_black mb" i18n>Resultados de tu búsqueda</h2>

    <div class="domainRegistered">
      <div class="domainRegistered__icon">
        <ng-template [ngIf]="isLoading && !forceStopLoading">
          <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
        </ng-template>
        <ng-template [ngIf]="response">
          <img *ngIf="response.is_available" src="/assets/images/icons/domains/Icon_domain_check.svg" alt="icon domain available">
          <img *ngIf="!response.is_available" src="/assets/images/icons/domains/icon_domain_registered.svg" alt="icon domain registered">
        </ng-template>
      </div>
      <div class="domainRegistered__info">
        <div class="domainRegistered__info-domain">
          {{ searchedDomain }}
          <button *ngIf="response" class="btn btnBlue btnDomain" mat-button
            (click)="whois()" [disabled]="response.is_available">
            WHOIS
          </button>
        </div>
        <div class="domainRegistered__info-state" [ngClass]="isLoading ? 'has-progress-bar' : '' ">
          <ng-template [ngIf]="isLoading && !forceStopLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </ng-template>
          <ng-template [ngIf]="response && response.is_available">
            <div class="domainPrice">
              <span class="price_value" *ngIf="response?.prices[0]?.price != null">
                <span class="price_curency">{{ symbol }}</span>
                {{response.prices[0].price | currencyLocale}}
              </span>
              <span class="price_sub" i18n>Anual</span>
            </div>
          <button (click)="goToPlan( response.domain )" class="btn btnBlue btnDomain" mat-button>COMPRAR</button>
          </ng-template>
          <ng-template [ngIf]="response && !response.is_available" i18n>
            Registrado
          <button (click)="goToUrl(response.domain)" class="btn btnBlue btnDomain" mat-button>WWW</button>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- START CHILEAN DOMAIN-->
    <div class="wrap_domainInfo">
    <h3 *ngIf="!BHCL" class="title3">Dominios en Chile</h3>
    <div class="domainRegistered_cl" *ngIf="!BHCL">
        <ng-template [ngIf]="!chileanDomain.loaded">
            <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
          </ng-template>
          <ng-template [ngIf]="chileanDomain.loaded">
            <ng-template [ngIf]="chileanDomain.is_available">
              <img src="/assets/images/icons/domains/icon_check_circle.svg" alt="icon check">
            </ng-template>
            <ng-template [ngIf]="!chileanDomain.is_available">
              <img src="/assets/images/icons/domains/icon_close_circle.svg" alt="icon close">
            </ng-template>
          </ng-template>
        <div class="domainRegistered__info">
          <div class="domainRegistered__info-domain cl">

              <img *ngIf="chileanDomain" src="/assets/images/icons/domains/icon_cl.svg" alt="icon chilean domain">
              &nbsp;{{ searchedName + '.' + chileanDomain.tld }}
              <!-- START No Available -->
              <ng-template [ngIf]="chileanDomain.loaded && !chileanDomain.is_available" >
                <div class="domainRegistered__info-state cl_state">
                  &nbsp; No disponible
                </div>
              </ng-template>
              <!-- END No Available -->

              <!-- START Domain avaible more button to buy -->
              <ng-template [ngIf]="chileanDomain.loaded && chileanDomain.is_available">
                  <div class="domainPrice _cl">
                    <span class="price_value">
                      <span class="price_curency">{{ symbol }}</span>
                      {{chileanDomain.price | currencyLocale}}
                    </span>
                    <span class="price_sub" i18n>Anual</span>
                  </div>
                <button (click)="goToPlan( searchedName + '.' + chileanDomain.tld )" class="btn_cl btn_buy btn btnBlue btnDomain" mat-button>COMPRAR</button>
              </ng-template>
              <!-- END Domain avaible more button to buy -->

              <!-- START Buttons when is Register-->
              <button class="btn_cl btn btnBlue btnDomain btnDomain_cl" mat-button
              (click)="whois()" [disabled]="chileanDomain.is_available">
              WHOIS
            </button>
              <ng-template [ngIf]="chileanDomain.loaded && !chileanDomain.is_available">
              <button (click)="goToUrl( searchedName + '.' + chileanDomain.tld  )" [disabled]="chileanDomain.is_available" class="btn_cl btn btnBlue btnDomain_clw btnDomain " mat-button>WWW</button>
              </ng-template>
              <!-- END Buttons when is Register-->
          </div>
        </div>
      </div>
    </div>
    <!--END CHILEAN DOMAIN-->
    <div class="wrap_domainInfo">
      <h3 class="title3" i18n>Dominios Recomendados</h3>

      <div class="domainInfo" *ngFor="let domain of relatedDomains">

        <ng-template [ngIf]="(isLoading || !domain.loaded) && !forceStopLoading">
          <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
        </ng-template>
        <ng-template [ngIf]="domain.loaded">
          <ng-template [ngIf]="domain.is_available">
            <img src="/assets/images/icons/domains/icon_check_circle.svg" alt="icon check">
          </ng-template>
          <ng-template [ngIf]="!domain.is_available">
            <img src="/assets/images/icons/domains/icon_close_circle.svg" alt="icon close">
          </ng-template>
        </ng-template>


        <span class="domainInfo__name" [ngClass]="(isLoading || !domain.loaded) ? 'margin-l-10' : ''">
          <span class="domainInfo__domain">{{domain.tld}}</span>
          {{ searchedName + '.' + domain.tld }}
        </span>

        <div class="domainInfo__inner" *ngIf="(isLoading || !domain.loaded) && !forceStopLoading">
          <button class="btn btnBlue btnDomain" mat-button [disabled]="true">COMPRAR</button>
        </div>
        <div class="domainInfo__inner" *ngIf="domain.loaded">
          <div class="domainInfo__price" *ngIf="domain.is_available; else isNotAvailPrice">
            <span class="price_value">
              <span class="price_curency">{{ symbol }}</span>
              {{ domain.price | currencyLocale }}
            </span>
            <span class="price_sub" i18n>Anual</span>
          </div>
          <ng-template #isNotAvailPrice><div class="domainInfo__price" i18n>No disponible</div></ng-template>
          <button (click)="goToPlan( searchedName + '.' + domain.tld )" class="btn btnBlue btnDomain" mat-button i18n [disabled]="!domain.is_available">COMPRAR</button>
      </div>
      </div>
    </div>
    <div class="center" *ngIf="isComplete && !forceStopLoading">
      <button class="saveResult" mat-button (click)="PDF()" [disabled]="isLoadingBtnPDF">
        <span [class.hidden]="isLoadingBtnPDF">
          <img src="/assets/images/icons/icon_file_pdf.svg" alt="save file pdf">
          <span i18n>Guardar resultados</span>
        </span>
        <span [class.hidden]="!isLoadingBtnPDF">
          <mat-progress-spinner [diameter]="40" mode="indeterminate"></mat-progress-spinner>
        </span>
      </button>
    </div>
  </div>
</div>
<!-- END domainInfo and domainRegistered -->

<!-- START resultSearchDomain -->
<div class="pad2" *ngIf="isShowResultDomain">
  <div class="container">
    <h2 class="ttl2_black mb" i18n>Resultados de tu búsqueda</h2>

    <div class="resultSearchDomain">
      <div class="resultSearchDomain__ttl" *ngIf="response?.domain != null">{{ 'www.' + response.domain }}</div>

      <div class="resultSearchDomain__line" *ngIf="response?.whois?.owner != null">
        <span class="medium" i18n>Titular</span>
        <span>{{ response.whois.owner }}</span>
      </div>
      <div class="resultSearchDomain__line" *ngIf="response?.whois?.register != null">
        <span class="medium" i18n>Agente registrador</span>
        <span>{{ response.whois.register }}</span>
      </div>
      <div class="resultSearchDomain__line" *ngIf="response?.whois?.creation_date != null">
        <span class="medium" i18n>Fecha de creación</span>
        <span>{{ response.whois.creation_date | dateLocale }}</span>
      </div>
      <div class="resultSearchDomain__line" *ngIf="response?.whois?.expiration_date != null">
        <span class="medium" i18n>Fecha de expiración</span>
        <span>{{ response.whois.expiration_date | dateLocale }}</span>
      </div>
      <ng-template ngFor let-dns_item [ngForOf]="response?.whois?.dns">
        <div class="resultSearchDomain__line">
          <span class="medium" i18n>Servidor de nombre</span>
          <span>{{ dns_item }}</span>
        </div>
      </ng-template>


      <div class="wrapBtn center">
        <button class="btn btnRed" mat-button i18n (click)="backFromWhois()">Volver</button>
      </div>

    </div>

  </div>
</div>
<!-- END resultSearchDomain-->

<!-- START -->
<div class="pad bg_grey" *ngIf="isShowResultDomainName && !isShowResultDomain">
  <div class="container">
    <h2 class="ttl2_black" i18n>Registra tu dominio .cl con nosotros</h2>
    <div class="sub_ttl2" i18n>Obtén un dominio chileno para tu sitio con BlueHosting</div>

    <ul class="listDomain">
      <li>
        <img src="/assets/images/icons/domains/icon_saving.svg" alt="saving">
        <span i18n>El mejor precio en comparación con otros registradores</span>
      </li>
      <li>
        <img src="/assets/images/icons/domains/icon_reliability.svg" alt="reliability">
        <span i18n>Regístrate con el dominio más popular y confiable entre los chilenos</span>
      </li>
    </ul>

  </div>
</div>
<!-- END -->

<!-- START -->
<section class="pad_top" *ngIf="isShowResultDomainName && !isShowResultDomain" [preRender]="false" (deferLoad)="showImage1 = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>NOSOTROS TE AYUDAMOS</div>
    <h2 class="ttl2_black mb" i18n>Ven a BlueHosting</h2>

    <div class="wrapCollumns">
      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/text-column/img13_column.svg" 
                  width="155px"
                  height="160px"
                  alt="Más rapidez">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height2" i18n>Ventas y asistencia técnica</h3>
        <p i18n>¿Necesitas orientación? En BlueHosting tenemos las respuestas que buscas. Nuestros profesionales están dedicados a resolver tus dudas y consultas.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img
              *ngIf="locale == 'es-CL'"
              src="/assets/images/text-column/icon.cl_x1.png"
              width="155px"
              height="155px"
              srcset="/assets/images/text-column/icon.cl_x2.png 2x" alt="Más facilidad">
            <img *ngIf="locale != 'es-CL'" 
                  src="/assets/images/text-column/img14_column.svg" 
                  width="155px"
                  height="155px"
                  alt="Más facilidad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height2" i18n>Más nombres a mejores precios</h3>
        <p i18n>Accede a lo mejores dominios. Escoge un dominio cercano y confiable con BlueHosting. Registra tu dominio .CL con nosotros.</p>
      </div>

      <div class="wrapCollumns_column">
        <div class="wrapCollumns_column__img">
          <ng-container *ngIf="showImage1">
            <img  src="/assets/images/text-column/img15_column.svg" 
                  width="176px"
                  height="154px"
                  alt="Más seguridad">
          </ng-container>
        </div>
        <h3 class="ttl3 upper ttl_height2" i18n>Registra tu dominio con el nº 1 de Chile</h3>
        <p i18n>Prepárate para alcanzar el éxito en línea con Bluehosting. Nuestros clientes y dominios son nuestro respaldo para llevarte a otro nivel.</p>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START  -->
<section class="pad" #domainCardSection id="domainCardSection" [preRender]="false" (deferLoad)="showImage2 = true">
  <div class="container">
    <div class="sup_ttl2_black" i18n>dominios</div>
    <h2 class="ttl2_black mb" i18n>Dominios para todas tus necesidades</h2>
  </div>

  <div class="domainCards">
    <div class="domainCards__item" *ngFor="let item of domainCards">
      <div class="name">{{item.tld}}</div>
      <p class="price">
        <span class="price_actual">
          <span class="currency">{{ symbol }}</span>
          {{item.price | currencyLocale}}
        </span>
      </p>
      <p class="price_sub" i18n>Anual + IVA</p>
      <div class="domainCards__item-info">{{item.info}}</div>
    </div>
  </div>

</section>
<!-- END  -->

<!-- START blockInfo -->
<section class="blockInfo bg_blue_dark2 pad" *ngIf="isShowResultDomainName && !isShowResultDomain">
  <div class="container">
    <div class="blockInfo__wrap">
      <div class="blockInfo__txt w100 center">
        <h2 class="ttl2 center" i18n>¿Necesitas ayuda?</h2>
        <p i18n>Llama a nuestro equipo de asistencia técnica de Lunes a Viernes <br> desde 9:00 hasta las 18:00 horas. <a href="tel:{{contact.main_phone_href}}">{{contact.main_phone}}</a></p>
      </div>
    </div>
  </div>
</section>
<!-- END blockInfo -->

<!-- START -->
<section class="pad bg_grey" *ngIf="isShowResultDomainName && !isShowResultDomain">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características de nuestro servicio</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>Bluehosting mejora el rendimiento de tu sitio con diversas tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on line. Bluehosting trabaja con WAF, DDoS y SSL
            para defender tu sitio</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage2">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>VISIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots, que tecnologías de análisis convencionales
            no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- START block_logo-->
<app-block-logos></app-block-logos>
<!-- END block_logo -->

<app-footer></app-footer>
