// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export class URL {
  public static readonly SUPPORT = 'https://help.haulmer.com/';
  public static readonly CONTACT = '/contactar-con-ventas/.';
  public static readonly BLUEHOSTING_WEBSITE = 'https://website-bluehosting-LANG_LOWER.haulmer.dev/';
  public static readonly BLUEHOSTING_FACEBOOK = 'https://www.facebook.com/pg/BlueHostingLAT';
  public static readonly BLUEHOSTING_TWITTER = 'https://twitter.com/bluehostinglat';
  public static readonly BLUEHOSTING_YOUTUBE = 'https://www.youtube.com/channel/UCEEla1DpAuIeP0Myd7ebzqQ';
  public static readonly BLUEHOSTING_LINKEDIN = 'https://www.linkedin.com/company/bluehosting/';
  public static readonly HAULMER_WEBSITE = 'https://www.haulmer.com/';
  public static readonly HAULMER_WORK_WITH_US = 'mailto:career@haulmer.com';
  public static readonly BLUEHOSTING_SPEEDTEST = 'http://speed.bluehosting.host/';
  public static readonly OPENCARGO_WEBSITE = 'https://opencargo.cl';
  public static readonly OPENCLOUD_WEBSITE = 'https://opencloud.cl';
  public static readonly PAGOMASTER_WEBSITE = 'https://pagomaster.cl';
  public static readonly BLUEHOSTING_LOGIN = 'https://panel.bluehosting.host/clientarea.php';
  public static readonly HAULMER_BLOG = 'https://www.haulmer.com/blog/';
  public static readonly HAULMER_NEWS = 'https://www.haulmer.com/news/';
  public static readonly OPENFACTURA_WEBSITE = 'https://www.openfactura.cl/';
  public static readonly CHILEFIRMAS_WEBSITE = 'https://www.chilefirmas.cl/';
  public static readonly API_DOMAIN = 'https://api.haulmer.dev/v1/domain/getdomain.php';
  public static readonly BLUEHOSTING_TERMS_CONDITIONS = "https://www.haulmer.com/legal/tag/web-hosting/";
  public static readonly TUU_WEBSITE = 'https://www.tuu.cl/';
  public static readonly FIND_INVOICE = "https://whmcs-base.haulmer.dev/includes/api/findInvoices.php";
}
