
import { EmprendedorComponent } from './emprendedor/emprendedor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PymesComponent } from './pymes/pymes.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { DominioComponent } from './dominio/dominio.component';
import { ResellerComponent } from './reseller/reseller.component';
import { CloudComponent } from './cloud/cloud.component';
import { CloudWithBackupComponent } from './cloud-with-backup/cloud-with-backup.component';
import { ContactWrapperComponent } from './contact/contact-wrapper.component';
import { WordpressComponent } from './wordpress/wordpress.component';
import { ContactSupportWrapperComponent } from './contact-support-wrapper/contact-support-wrapper.component';
import { PaymentFormsWrapperComponent } from './payment-forms-wrapper/payment-forms-wrapper.component';
import { SuccessComponent } from './success/success.component';
import { SuccessGuard } from './success/success.guard';
import { DirectPaymentComponent } from './direct-payment/direct-payment.component';


export const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'web-hosting/.',
        component: EmprendedorComponent,
    },
    {
        path: 'web-hosting',
        redirectTo: 'web-hosting/.',
        pathMatch: 'full'
    },
    {
        path: 'web-hosting-negocio/.',
        component: PymesComponent
    },
    {
        path: 'web-hosting-negocio',
        redirectTo: 'web-hosting-negocio/.',
        pathMatch: 'full'
    },
    {
        path: 'hosting-negocio-wordpress/.',
        component: WordpressComponent
    },
    {
        path: 'hosting-negocio-wordpress',
        redirectTo: 'hosting-negocio-wordpress/.',
        pathMatch: 'full'
    },
    {
        path: 'web-hosting-empresa/.',
        component: EmpresaComponent
    },
    {
        path: 'web-hosting-empresa',
        redirectTo: 'web-hosting-empresa/.',
        pathMatch: 'full'
    },
    {
        path: 'certificados-ssl/.',
        component: CertificadoComponent
    },
    {
        path: 'certificados-ssl',
        redirectTo: 'certificados-ssl/.',
        pathMatch: 'full'
    },
    {
        path: 'dominios/.',
        component: DominioComponent
    },
    {
        path: 'dominios',
        redirectTo: 'dominios/.',
        pathMatch: 'full'
    },
    {
        path: 'hosting-reseller/.',
        component: ResellerComponent
    },
    {
        path: 'hosting-reseller',
        redirectTo: 'hosting-reseller/.',
        pathMatch: 'full'
    },
    {
        path: 'condiciones',
        redirectTo: 'condiciones/.',
        pathMatch: 'full'
    },
    {
        path: 'vps/.',
        component: CloudComponent
    },
    {
        path: 'vps',
        redirectTo: 'vps/.',
        pathMatch: 'full'
    },
    {
        path: 'vps-con-backup/.',
        component: CloudWithBackupComponent
    },
    {
        path: 'vps-con-backup',
        redirectTo: 'vps-con-backup/.',
        pathMatch: 'full'
    },
    {
        path: 'success',
        component: SuccessComponent,
        canActivate: [SuccessGuard],
    },
    {
        path: 'contactar-con-ventas/.',
        component: ContactWrapperComponent
    },
    {
        path: 'contactar-con-ventas',
        redirectTo: 'contactar-con-ventas/.',
        pathMatch: 'full'
    },
    {
        path: 'contactar-con-soporte/.',
        component: ContactSupportWrapperComponent
    },
    {
        path: 'contactar-con-soporte',
        redirectTo: 'contactar-con-soporte/.',
        pathMatch: 'full'
    },
    {
        path: 'formas-de-pago/.',
        component: PaymentFormsWrapperComponent
    },
    {
        path: 'formas-de-pago',
        redirectTo: 'formas-de-pago/.',
        pathMatch: 'full'
    },
    {
        path: 'pago-directo/.',
        component: DirectPaymentComponent
    },
    {
        path: 'pago-directo',
        redirectTo: 'pago-directo/.',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
