<!-- START firstScreen -->
<div [ngClass]="{'certificado': true}">
  <section class="firstScreen">
    <div class="container">
      <div class="firstScreen__inner">
        <h1 class="firstScreen__ttl" i18n>¡ADQUIERE LA MEJOR DEFENSA <br> CON TU <br> CERTIFICADO SSL!</h1>
        <div class="firstScreen__sub_ttl" i18n>Certificado SSL desde <br>
          <span>{{ symbol }}</span>{{ ssl_certificates[0].periods[0].price | currencyLocale }} anuales
        </div>
        <div class="wrapBtn">
          <button (click)="navigateToSection( 'pad' )" class="btn btnRed" mat-button i18n>Contrata aquí tu plan</button>
        </div>
      </div>
    </div>
  </section>
</div>
<!-- END firstScreen -->

<!-- START -->
<section class="pad bg_green_gradient pad_from_menu">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Planes</div>
    <h2 class="ttl2_black mb" i18n>Menor precio, mayor seguridad</h2>

    <div class="wrapCardPrice col_4 col_3">

      <div class="wrapCardPrice pad_buttom">
        <div class="cardPrice" *ngFor="let plan of ssl_certificates">
          <div class="cardPrice__top">
            <div class="cardPrice_ttl"></div>
            <div class="cardPrice__ttl">{{ plan.name }}</div>
            <p class="cardPrice__sub_ttl">{{ plan.sub }}</p>
            <div *ngFor="let period of plan.periods; let i = index">
              <p class="price price_ttal">
                <span *ngIf="i > 0" class="price_pre">
                  <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i-1].price | currencyLocale }}</span>
                <span class="price_actual">
                  <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale }}</span>
              </p>
              <p class="price_sub" i18n *ngIf="i == 0">Anual + IVA</p>
              <p class="price_sub" i18n *ngIf="i > 0">Anual + IVA, al contratar por {{ period.period }}</p>
            </div>
          </div>

          <button id="certificado-contratar-{{plan.name}}" class="btn btnRed" mat-button (click)="goToUrl( plan.url )" i18n>contratar</button>

          <ul class="card_list">
            <li *ngFor="let feature of plan.features">
              <span [innerHTML]="feature.text"></span>
              <img src="{{ feature.icon_url }}" alt="{{ feature.text }}">
              <a class="has-icon" aria-label="Más información" *ngIf="feature.text=='<b>30 días</b> de reembolso'" href="https://www.haulmer.com/legal/garantia-de-reembolso/" target="_blank" style="text-decoration: none;"></a>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</section>
<!-- END -->

<!-- START menuPlans-->
<app-menu-plans *ngIf="isShowPlans"></app-menu-plans>
<!-- END menuPlans-->

<!-- START blockList -->
<app-block-list [lists]="lists" [preRender]="false" (deferLoad)="showImage1 = true"></app-block-list>
<!-- END blockList -->

<!-- START -->
<section class="pad">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Características</div>
    <h2 class="ttl2_black mb" i18n>Prepárate para más</h2>

    <div class="content">
      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img12_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Confianza, un bien incalculable</h3>
          <p i18n>Con la obtención de un certificado SSL para tu página web evitarás el temido mensaje de “Sitio no
            seguro” que ahuyenta a todos los posibles visitantes cuando ingresan a tu sitio web.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img13_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Certificado SSL</h3>
          <p i18n>Somos proveedores de SSL y por ello emitimos de manera óptima certificados contables para los
            navegadores, cumpliendo así con todas las directrices de validación.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img14_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Pagar de manera segura</h3>
          <p i18n>Tus clientes deben y desean poder tener acceso a todos los medios de pago en tu sitio y el SSL lo
            simplifica clic tras clic. No deseas que tu cliente abandone tu sitio justo antes de pagar ¿o sí?</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img15_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>sé la primera opción en google</h3>
          <p i18n>Si tus futuros clientes buscan en motores de búsqueda, palabras relacionadas con tu servicio o
            producto, tu sitio debe ser el primero en aparecer y ganar la opción del clic
            de tu futuro cliente. Todo esto gracias a un SSL.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img16_service.svg" alt="imagen de conversión">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Protección de información</h3>
          <p i18n>Un SSL cifra los datos e información que tus clientes te envían. Solo cuando los recibes son
            descifrados y de esta manera viajan seguros en la web.</p>
        </div>
      </div>

      <div class="flex_wrapper flex_wrapper-r flex_wrapper-r2">
        <div class="wrap_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img17_service.svg" alt="imagen de tráfico">
          </ng-container>
        </div>
        <div class="flex_inner">
          <h3 class="ttl3 upper" i18n>Incorruptible</h3>
          <p i18n>Utilizamos el cifrado más poderoso de la actualidad. Todo por los datos de tus clientes, el SHA-2 y de
            2048 bits, son inmejorables en el resguardo de datos a través de la web.</p>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START -->
<section class="pad bg_grey2">
  <div class="container">
    <div class="sup_ttl2_black" i18n>El Hosting para tus necesidades</div>
    <h2 class="ttl2_black mb" i18n>BlueHosting, campo de visión 360º</h2>

    <div class="wrapCollumn">
      <div class="wrapCollumn__column">
        <h3 class="ttl3 upper" i18n>Más seguridad, más confianza</h3>
        <p i18n>Mantenemos a salvo tu información gracias a las más sofisticadas herramientas de detección y eliminación
          de infecciones, lo que se traduce en más confianza para tus clientes frente a posibles hackeos.</p>
        <h3 class="ttl3 upper" i18n>SSL, Protección de información</h3>
        <p i18n>Un SSL cifra los datos e información que tus clientes te envían cada vez que ingresan a un sitio web
          protegido. Solo cuando los recibes son descifrados. Imagina esta seguridad en cada página y subdominio.</p>
        <h3 class="ttl3 upper" i18n>Misión cumplida</h3>
        <p i18n>2048 bits son una cantidad de información incorruptible para cualquier intruso informático. Tu sitio se
          encuentra cifrado y seguro ante cualquier tipo de ataque inesperado que desee acceder. ¡Felicitaciones!</p>
      </div>
      <div class="wrapCollumn__img">
        <ng-container>
          <img  src="/assets/images/img_column.svg" 
                width="340px"
                height="233px"
                alt="El Hosting para tu Negocio">
        </ng-container>
      </div>
    </div>

  </div>
</section>
<!-- END -->

<!-- START blockInfo -->
<app-block-info-simple [info]="info"></app-block-info-simple>
<!-- END blockInfo -->

<!-- START -->
<section class="pad_top bg_grey">
  <div class="container">
    <div class="sup_ttl2_black" i18n>Hacemos del internet el lugar que esperas</div>
    <h2 class="ttl2_black" i18n>Tu experiencia digital comienza aquí</h2>
    <p class="center" i18n>BlueHosting no solo protege, sino que acelera miles de sitios web y otras aplicaciones de
      internet</p>
    <!-- START wrapCard -->
    <div class="wrapCard">
      <div class="wrapCard__item bg_blue1">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img-card-item1.svg" alt="redimiento">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>RENDIMIENTO</h3>
          <p i18n>BlueHosting mejora el rendimiento de tu sitio con diversos tipologías de optimización.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue2">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img-card-item2.svg" alt="seguridad">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>SEGURIDAD</h3>
          <p i18n>Protege tu sitio y a quienes lo visitan de todo tipo de engaños on-line. BlueHosting utiliza con WAF
            y SSL entre otras herramientas para defender tu sitio.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue3">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img-card-item3.svg" alt="confianza">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>CONFIANZA</h3>
          <p i18n>Administramos un servicio DNS con más de un 30% de cuota de mercado.</p>
        </div>
      </div>

      <div class="wrapCard__item bg_blue4">
        <div class="wrapCard__item_img">
          <ng-container *ngIf="showImage1">
            <img src="/assets/images/img-card-item4.svg" alt="visión">
          </ng-container>
        </div>
        <div class="wrapCard__item__info">
          <h3 class="wrapCard__item_ttl upper" i18n>PREVENCIÓN</h3>
          <p i18n>Nuestra red es capaz de identificar visitantes y comportamientos de bots, que tecnologías de
            análisis convencionales
            no logran reconocer.</p>
        </div>
      </div>
    </div>
    <!-- END wrapCard -->
  </div>
</section>
<!-- END  -->

<!-- START block_txt_column -->
<section class="block_txt_column pad">
  <div class="container">
    <h2 class="ttl2_black" i18n>Preguntas frecuentes</h2>
    <div class="txt_column">

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Para qué sirve un Certificado SSL?</h3>
        <p i18n>Principalmente garantiza una comunicación cifrada entre tu sitio y tus clientes, lo que se traduce en la
          protección de tus visitas, un aumento en la confianza en tu sitio y en un mejor posicionamiento web.</p>
        <h3 class="ttl3" i18n>¿Mi certificado es válido para mi dominio y todos mis subdominios?</h3>
        <p i18n>Dependerá principalmente de qué tipo de certificado hayas contratado para proteger tu dominio y tus
          subdominios. Para la protección de ambos, recomendamos un certificado Wildcard, el que protegerá por ejemplo:
          blog.midominio.cl - intranet.midominio.cl - galeria.midominio.cl</p>
        <h3 class="ttl3" i18n>¿Cómo renuevo mi certificado de seguridad?</h3>
        <p i18n>Solo debes efectuar la compra de uno nuevo y de esta manera, tu sitio web continuará estando a salvo.
        </p>
      </div>

      <div class="txt_column__column">
        <h3 class="ttl3" i18n>¿Cuál es la fecha de vencimiento de mi certificado?</h3>
        <p i18n>Las fechas de vencimiento varían según su tipo. Puedes verificar la tuya en tu navegador. A través de
          Chrome, deberás presionar el candado verde ubicado al principio de la barra de direcciones. Después de esto,
          se desplegará un menú en donde podrás ingresar a “Certificado” para encontrar la información necesaria.</p>
        <h3 class="ttl3" i18n>¿BlueHosting me provee un certificado de seguridad?</h3>
        <p i18n>Si, proveemos un certificado SSL básico que va incluido en tu plan web hosting. Este se configura
          automáticamente al momento de crear tu servicio; su renovación se efectúa de forma automática por lo que tus
          clientes estarán protegidos en todo momento.</p>
      </div>

    </div>
  </div>
</section>
<!-- END block_txt_column -->

<!-- START block_logo -->
<app-block-logos></app-block-logos>
<!-- END block_logo -->

<app-footer></app-footer>