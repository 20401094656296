<section class="blockInfo bg_red">
  <div class="container">
    <div class="blockInfo__wrap">
      <div class="blockInfo__txt">
        <h2 class="ttl2" i18n>{{info.title}}</h2>
        <p i18n>Más potencia y mayor velocidad desde {{ info.symbol }}{{ info.price | currencyLocale }} anual.</p>
      </div>
      <a (click)="scrollTop()" [routerLink]="[info.url]" class="btn btnWhite" mat-button i18n>CONÓCELO AQUÍ</a>
    </div>
  </div>
</section>