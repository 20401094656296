<!-- START tabPlans -->
<div class="tabPlans">
  <!-- start nav -->
  <div class="tabPlans__nav">
    <ng-template ngFor let-category [ngForOf]="hosting" let-i="index">
      <button class="tabPlans__nav_btn btn_personas" mat-button (click)="openPlan(category.id); addClassActive($event)" [ngClass]="i == 0 ? 'active' : ''">{{ category.category }}
      </button>
    </ng-template>
  </div>
  <!-- end nav -->
  <!-- start plan -->
  <div class="tabPlans__content" *ngFor="let plan of categoryToShow.plans; let odd=odd">
    <!-- start item 1 -->
    <div class="tabPlans__item" [ngClass]="odd ? 'bg_grey': ''">

      <div class="tabPlans__item_info">
        <h3 class="ttl3 upper">{{ plan.name }}</h3>
        <p>{{ plan.sub_detail }}</p>
        <div class="tabPlans__item_info-note">
          <p>Plan recomendable para:</p>
          <div class="wrapLink">
            <span class="link" *ngFor="let rec of plan.recommended_to">{{ rec }}</span>
          </div>
        </div>
      </div>
      <div class="tabPlans__item_price">
        <div class="price__inner">
          <p class="price price_total">
            <span class="price_actual">
              <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[0].price | currencyLocale}}</span>
          </p>
          <p class="price_sub" i18n>Anual + IVA</p>
          <button id="tabplans-{{activeCategoryName}}-contratar-{{plan.name}}" class="btn btnRedTransp" (click)="goToUrl( plan.url )" mat-button i18n>contratar</button>
        </div>
        <div class="price__inner">
          <div *ngFor="let period of plan.periods | slice:1; let i = index">
            <p class="price">
              <span class="price_pre">
                <span class="currency valuta">{{ symbol }}</span>{{ plan.periods[i].price | currencyLocale}}</span>
              <span class="price_actual">
                <span class="currency valuta">{{ symbol }}</span>{{ period.price | currencyLocale}}</span>
            </p>
            <p class="price_sub" i18n>Anual + IVA, al contratar por {{ period.period }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- end item 1 -->
  </div>
  <!-- end plan -->

</div>
<!-- END tabPlans -->