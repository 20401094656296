<h2 class="ttl2">Selecciona el plan óptimo para tus necesidades comerciales</h2>
<div class="typeSyteInfo">

  <div class="typeSyteInfo__aside">
    <div class="typeSyteInfo__top">
      <button class="btnPrev" mat-icon-button mat-dialog-close></button>
      <span>Ver opciones</span>
    </div>
    <div class="typeSyteInfo__aside_inner">
      <img src="/assets/images/img-blog.svg" alt="blog">
    </div>
  </div>

  <div class="typeSyteInfo__body">
    <div class="typeSyteInfo__top">
      <h2 class="ttl2">E-Commerce</h2>
    </div>
    <div class="typeSyteInfo__body_inner">
      <ul class="list_check">
        <li>DDoS; mitigación y eliminación de malware</li>
        <li>Contenedor MySQL exclusivo para aislar bases de datos de recursos compartidos</li>
        <li>Instalación de CMS y carros en un solo clic para aumentar tiendas rápidamente</li>
        <li>Arquitectura en clúster, única en su tipo para minimizar el tiempo de inactividad</li>
        <li>Red de entrega de contenido global para tiempos de carga rápidos en todo el mundo</li>
      </ul>
      <button (click)="goToUrl()" class="btn btnRed" mat-button>CONTRATAR</button>
    </div>
  </div>

</div>